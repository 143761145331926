import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { format, addYears, set, setDate } from "date-fns";

//services
import paymentsService from "../../../../../../../../services/payments-service";

// Assets

//Mui Components
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  Typography,
} from "@mui/material";

//Mui icons
import CloseIcon from "@mui/icons-material/Close";

//Our Components
import PaymentBankAccounts from "../../card-components/PaymentBankAccounts";
import PaymentInputs from "./PaymentInputs";
import PayoutInputs from "./PayoutInputs";
import ButtonInfinityLoader from "../../../../../../../UH-loading-animation/ButtonInfinityLoader";

//utils
import { formatUTCDateToObjWithTimeZone, formatDateObjYYYYMMDD, formatDateObjMMDDYYYYDisplay, formatUTCToShortDate, convertUserTimeToMST } from "../../../../../../../utils";

//component styles
const componentStyles = {
  // drawer:(loading) =>({
  //   overFlow: loading ? "hidden" : "auto",
  // }),
  componentBody: {
    fontFamily: "Poppins",
    minWidth: "545px",
    maxWidth: "545px",
    height: "100%",
    backgroundColor: "background.default",
  },
  headerContainer: {
    width: "100%",
    padding: "13px 38px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 auto",
    borderBottom: "2px solid",
    borderColor: "primary.main",
  },
  title: {
    color: "text.primary",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: "400",
  },
  description: {
    color: "text.primary",
    fontFamily: "Archivo",
    fontSize: "14px",
    fontWeight: "500",
    padding: "10px 16px",
  },
  closeDrawerButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  interactionBody: {
    padding: "34px 32px 8px",
    flexWrap: "wrap",
  },
  amountBreakdownRows: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 15px",
    borderBottom: "1px solid",
    borderColor: "background.border",
  },
  totalAmountTextContainer: {
    display: 'flex', 
    flexDirection: 'column'
  },
  totalAmountText: {
    marginRight: "10px",
    fontWeight: '400px',
    fontSize: "14px!important",
    height: '20px'
  },
  transFeeMessage: {
    fontSize: "12px!important", 
    color: 'text.disabled', 
    height: '20px', 
    lineHeight:'19px', 
    letterSpacing: '0.4px'
  }

};
export default function MakePaymentDrawer(props) {

  const [loading, setLoading] = useState(false);

  //Payment Date Range
    let currentDate = new Date()
    let tomorrowDate = new Date(currentDate)
    tomorrowDate.setDate(currentDate.getDate() + 1)

    const [dateMinObj, setDateMinObj] = useState(currentDate) //this changes frequently based on time of day
    let dateMaxObj = addYears(new Date(), 1)

    //DATE FORMATTING FOR CALCULATED NEXT OCCURRENCE ususally received in UTC - convert to date obj to be accepted by date picker
    let dateObjFirstPaySchedule = props?.paymentSchedule && props.paymentSchedule[0]?.calculatedNextOccurrence ? formatUTCDateToObjWithTimeZone(props.paymentSchedule[0]?.calculatedNextOccurrence) : null;

    //need to check time of day before setting dateMinObj - if earlier than 3pm MST, set to today, else set to tomorrow
    const determineDateMin = () => {
      //this function converts user's time to MST in 24hr format
      let _currentTime = convertUserTimeToMST()
      if(_currentTime < '15:00:00'){
        setDateMinObj(currentDate)
      }else {
        setDateMinObj(tomorrowDate)
      }
    }

    useEffect(() => {
      determineDateMin()
    }, [props?.paymentDrawerOpen])

//NEW Payment Object - set to default values
  
  const [isCustomDate, setIsCustomDate] = useState(true);
  const [includeTransactionFee, setIncludeTransactionFee] = useState({scheduleDayOfMonth: null, scheduleAmount: null, schedulePaymentFee: 0, includeSeparateFee: null })
  const [transactionFee, setTransactionFee] = useState(0)
  const [selectedPaymentTransactionType, setSelectedPaymentTransactionType] =
    useState(1);
  const [newPaymentObj, setNewPaymentObj] = useState({
    paymentTransactionTypeId: 1,
    customPayment: false,
    customAmount: 0,
    paymentAmount: props?.paymentSchedule?.length > 0 ? props?.paymentSchedule[0]?.paymentAmount : 0, 
    customDate: includeTransactionFee?.includeSeparateFee ? true : false, //this value is checked on component load - includeSeparateFee = custom date selected
    paymentDate: dateMinObj,
    enrollmentId: props.enrollments[0]?.enrollmentId,
    groupPlanId: 0,
    paymentMethod: {},
   
  });

  //on drawer open - set newPaymentObj value to single monthly scheduled payment amount
  useEffect(() => {
    setNewPaymentObj({
      paymentTransactionTypeId: 1,
      customPayment: false,
      customAmount: 0,
      paymentAmount:  props?.paymentSchedule?.length > 0 ? props?.paymentSchedule[0]?.paymentAmount : 0,
      customDate: includeTransactionFee?.includeSeparateFee ? true : false, //this value is checked on component load - includeSeparateFee = custom date selected
      paymentDate: dateMinObj,
      enrollmentId: props.enrollments[0]?.enrollmentId,
      groupPlanId: 0,
      paymentMethod: {},
    })
  }, [props?.paymentDrawerOpen])

  //check payment schedule changes for custom date vs. scheduled date to apply trans fee
  const determineTransFee = (selectedDate) => {
        //check if multiple payment schedules exist
    //Arrays of days of month
    let _firstPaymentSchedule = null
    let _firstPaymentScheduleDays = null
    let _secondPaymentSchedule = null
    let _secondPaymentScheduleDays = null
    if (props?.paymentSchedule?.length > 1) {
      //first pay sch
      _firstPaymentSchedule = props?.paymentSchedule[0]
      _firstPaymentScheduleDays =
        props?.paymentSchedule[0]?.scheduledPaymentDates?.map(
          (date) => date?.dayOfMonth
        )
     
        //second pay sch
      _secondPaymentSchedule = props?.paymentSchedule[1]
      _secondPaymentScheduleDays =
        props?.paymentSchedule[1]?.scheduledPaymentDates?.map(
          (date) => date?.dayOfMonth
        )
    
    } else if (props?.paymentSchedule?.length === 1) {
      _firstPaymentSchedule = props?.paymentSchedule[0]
      _firstPaymentScheduleDays =
        props?.paymentSchedule[0]?.scheduledPaymentDates?.map(
          (date) => date?.dayOfMonth
        )    
    }


    //check newPaymentObj payment date and determine if it is on a scheduled date
    let _selectedPaymentDateObj = new Date(selectedDate)
  
    let _selectedPaymentDateDayOfMonth = _selectedPaymentDateObj?.getDate()

    //if 2 pay scheudles, check if selected date is before vs. after pay schedule end date, then determine if it is on the same day of month as any scheduled payment dates in that schedule range
    if (props?.paymentSchedule?.length > 1) {
      let _firstPaymentScheduleEndDate = new Date(
        props?.paymentSchedule[0]?.endPaymentDate
      )
      let _secondPaymentScheduleEndDate = new Date(
        props?.paymentSchedule[1]?.endPaymentDate
      )

      if (
        _selectedPaymentDateObj < _firstPaymentScheduleEndDate &&
        _firstPaymentScheduleDays?.includes(_selectedPaymentDateDayOfMonth)
        && _firstPaymentSchedule?.isEnabled
      ) {
        //get index of payment day of month to correspond to paymount amount in paymentSchedule.payments object (add +1 to index to get correct payment amount from paymentSchedule.payments object)
        let indexOfDate = _firstPaymentScheduleDays?.indexOf(_selectedPaymentDateDayOfMonth) 
        // console.log('hit first pay schedule of 2', indexOfDate)
       setIncludeTransactionFee({...includeTransactionFee, scheduleDayOfMonth: _firstPaymentScheduleDays[indexOfDate], scheduleAmount: _firstPaymentSchedule?.payments[indexOfDate + 1], schedulePaymentFee: _firstPaymentSchedule?.transactionFee, includeSeparateFee: null})

      } else if (
        _selectedPaymentDateObj > _firstPaymentScheduleEndDate &&
        _secondPaymentScheduleDays?.includes(_selectedPaymentDateDayOfMonth)
        && _secondPaymentSchedule?.isEnabled
      ) { 

        //get index of payment day of month to correspond to paymount amount in paymentSchedule.payments object (add +1 to index to get correct payment amount from paymentSchedule.payments object)
        let indexOfDate = _secondPaymentScheduleDays?.indexOf(_selectedPaymentDateDayOfMonth)
        // console.log('hit second pay schedule of 2', indexOfDate)
       setIncludeTransactionFee({...includeTransactionFee, scheduleDayOfMonth: _secondPaymentScheduleDays[indexOfDate], scheduleAmount: _secondPaymentSchedule?.payments[indexOfDate + 1], schedulePaymentFee: _secondPaymentSchedule?.transactionFee, includeSeparateFee: null})
      } else {
       setIncludeTransactionFee({...includeTransactionFee, scheduleDayOfMonth: null, scheduleAmount: 0, schedulePaymentFee: 0, includeSeparateFee: true})
      }
    } else if (props?.paymentSchedule?.length === 1) {
      if (_firstPaymentScheduleDays?.includes(_selectedPaymentDateDayOfMonth)  && _firstPaymentSchedule?.isEnabled) {
        //get index of payment day of month to correspond to paymount amount in paymentSchedule.payments object (add +1 to index to get correct payment amount from paymentSchedule.payments object)
        let indexOfDate = _firstPaymentScheduleDays?.indexOf(_selectedPaymentDateDayOfMonth)
        // console.log('hit ONLY pay schedule', indexOfDate)
       setIncludeTransactionFee({...includeTransactionFee, scheduleDayOfMonth: _firstPaymentScheduleDays[indexOfDate], scheduleAmount: _firstPaymentSchedule?.payments[indexOfDate + 1], schedulePaymentFee: _firstPaymentSchedule?.transactionFee, includeSeparateFee: null})
      } else { 
        // console.log('hit else payment of 1', _firstPaymentSchedule?.isEnabled)
        setIncludeTransactionFee({...includeTransactionFee, scheduleDayOfMonth: null, scheduleAmount: 0, schedulePaymentFee: 0, includeSeparateFee: true})
      }
      
    }
  }

  //check for transaction fee on component load
  useEffect(() => {
    determineTransFee(newPaymentObj?.paymentDate)
  }, [props?.paymentDrawerOpen])

  //PAYOUT
  const [newPayoutObj, setNewPayoutObj] = useState({
    paymentTransactionTypeId: 4,
    paymentAmount: 0,
    description: "",
    enrollmentId: props.enrollments[0]?.enrollmentId,
    groupPlanId: 0,
    paymentMethod: {},
  });

  const paymentTransactionTypeChangeHandler = (e) => {
    let value = e.target.value;
    // setNewPaymentObj({
    //   ...newPaymentObj,
    //   paymentTransactionTypeId: value,
    // });
    setSelectedPaymentTransactionType(value);
  };

  // const [customAmountError, setCustomAmountError] = useState(false);

  //select one of the bank account listed to make a payment
  const [selectedBankAccount, setSelectedBankAccount] = useState(
    props.bankAccount?.find((account) => account.isPrimary === true)
  );

  const [paymentTransactionTypes, setPaymentTransactionTypes] = useState([
    ...props.paymentTransactionTypes,
  ]);

  //bank account type name
  const getMemberAccountType = (accountId) => {
    let type = props.bankAccountTypes?.find((type) => {
      return type.id === accountId;
    });
    return type.name;
  };

  useEffect(() => {
    if(props?.bankAccount?.length > 0) {
      let _selectedAccount = props?.bankAccount?.find((account) => account.isPrimary === true)
      setSelectedBankAccount(_selectedAccount)
    }
  }, [props?.bankAccount?.length, props?.bankAccount])
  

  const handleBankAccountSelect = (e, account) => {
    console.log("ACCOUNT SELECTD FROM RADIO", account);
    setSelectedBankAccount(account);
  };

  const handleSchedulePaymentClick = async () => {
    setLoading(true); 
    //check for custom date, otherwise default to next scheduled transaction date and if one does not exist, default to scheduled next occurance OR today if that does not exist
    let newDate = new Date() //default if no scheduled next occurance
    let _nextScheduledDate = props?.paymentSchedule[0]?.calculatedNextOccurrence ? formatUTCDateToObjWithTimeZone(props?.paymentSchedule[0]?.calculatedNextOccurrence) : newDate //convert utc date to date obj
    let _paymentDate = newPaymentObj?.customDate ? formatDateObjYYYYMMDD(newPaymentObj?.paymentDate) : formatDateObjYYYYMMDD( _nextScheduledDate); //format date obj to short date string 'yyyy-MM-dd'
    let _totalPaymentAmount = newPaymentObj?.paymentAmount + transactionFee;

    if (props.member?.id) {
      let createOneTimePayment = {
        memberId: props.member.id,
        paymentAmount: _totalPaymentAmount,
        occurance: _paymentDate,
        bankAccountId: selectedBankAccount?.id,
        enrollmentId: newPaymentObj.enrollmentId,
      };
      console.log("New Payment Submitted", createOneTimePayment);

      try {
        await paymentsService.insertOneTimePayment(createOneTimePayment);
        
      } catch (error) {
        console.error("error creating one time payment", error);
       
      }
    }
    //fetch new payments to update display
    props.getOneTimePayments();

    // // //close make payment drawer
    handleClosePaymentDrawer();

  };

  const handleIssuePayoutClick = async () => {
    setLoading(true);
    if (props.member) {
      let payoutObj = {
        memberId: props.member.id,
        paymentAmount:newPayoutObj.paymentAmount,
        bankAccountId: selectedBankAccount.id,
        description:newPayoutObj.description
      };
      console.log("New payout Submitted", payoutObj);
      try {
        await paymentsService.postPayout(payoutObj);
      } catch (error) {
        console.error("error creating payout", error);
      }
    }
    console.log("issuing payout",newPayoutObj,selectedBankAccount,props.member);
    //fetch new payments to update display
    // props.getOneTimePayments();
    props.getAllPayments()

    // //close make payment drawer
    handleClosePaymentDrawer();
  };

  const handleCancelClick = () => {
    //reset payment object
    setNewPaymentObj({
      paymentTransactionTypeId: 1,
      customPayment: false,
      customAmount: 0,
      paymentAmount: 0, //Balance or custom
      customDate: false,
      paymentDate: new Date(), //Next scheduled trans or custom
      groupPlanId: 0,
      enrollmentId: props.enrollments[0]?.enrollmentId,
      paymentMethod: {},
      
    });
    props.setPaymentDrawerOpen(false);
    props.setShowSelectBankAccount(false);
    props?.setRerunPaymentAmount(0);
    setTransactionFee(0)
  };

  //close make a payment drawer
  const handleClosePaymentDrawer = () => {
    //reset payment object
    setNewPaymentObj({
      paymentTransactionTypeId: 1,
      customPayment: false,
      customAmount: 0,
      paymentAmount: 0, //Balance or custom
      customDate: false,
      paymentDate: new Date(), //Next scheduled trans or custom
      groupPlanId: 0,
      enrollmentId: props.enrollments[0]?.enrollmentId,
      paymentMethod: {},
     
    });

    let newSelectedAccount = props.bankAccount?.find(
      (account) => account.isPrimary === true
    );
    setSelectedBankAccount(newSelectedAccount);
    props.setPaymentDrawerOpen(false);
    props.setShowSelectBankAccount(false);
    props?.setRerunPaymentAmount(0);
    setIncludeTransactionFee({scheduleDayOfMonth: null, scheduleAmount: null, schedulePaymentFee: 0, includeSeparateFee: null, })
    setTransactionFee(0)

    setLoading(false)
  };

  const calculateTransactionFee = () => {
    //if this is a rerun payment set to run on a NON scheduled payment date, transaction fee is 0, else it is -1 (rerun amount already includes transaction fee)
    if(props?.rerunPaymentAmount > 0 && includeTransactionFee?.includeSeparateFee){
      setTransactionFee(0)
    } else if(props?.rerunPaymentAmount > 0 && !includeTransactionFee?.includeSeparateFee){
      setTransactionFee(-1)
    }

    //if not a rerun payment
    if(props?.rerunPaymentAmount == 0){
      //if custom date is selected, transaction fee is 1
      if(includeTransactionFee?.includeSeparateFee){
        setTransactionFee(1)
      } else {
        //if custom date is not selected, additional transaction fee is 0
        setTransactionFee(0)
      }
    }
  }

  //update transaction fee when amount or date selected changes
  useEffect(() => {
    calculateTransactionFee()
    checkExistingPaymentsOnSameDate()
  }, [newPaymentObj?.paymentDate, includeTransactionFee?.scheduleDayOfMonth, props?.paymentDrawerOpen])

  //display total of all existing payments (other OTP + schedule payment) on same date in the Existing Payments row
  //Add total of al payments to display in the Total row
  const [totalExistingPayments, setTotalExistingPayments] = useState(0)

  const checkExistingPaymentsOnSameDate = () => {
    //initial amounts
    let _totalScheduledPaymentAmount = 0
    let _totalExistingOTPAmount = 0

    //Check EXISTING ONE TIME PAYMENTS on this date and add total to totalExistingOTPAmount
    let _existingOneTimePayments = props?.oneTimePayments?.filter(payment => {
      //format payment occurance (utc date string), and newPaymenbtObj payment date (date obj) to short date string to compare
      console.log('payment occurance', formatUTCToShortDate(payment.occurance), 'newPaymentObj?.paymentDate', formatDateObjMMDDYYYYDisplay(newPaymentObj?.paymentDate))
      return formatUTCToShortDate(payment.occurance) === formatDateObjMMDDYYYYDisplay(newPaymentObj?.paymentDate)
    })
    _totalExistingOTPAmount = _existingOneTimePayments?.reduce((acc, payment) => {
      return acc + payment.paymentAmount
    }, 0)
    console.log('total existing OTP payments', _totalExistingOTPAmount)

    //Check EXISTING SCHEDULED PAYMENTS on this date
    //Determine the payment amount for this date and add to total
    _totalScheduledPaymentAmount = includeTransactionFee?.scheduleAmount
    let _total = Number((_totalScheduledPaymentAmount + _totalExistingOTPAmount).toFixed(2))
    // console.log('total other existing payments', _total)
    setTotalExistingPayments(_total)

  }

  return (
    <>
      <Drawer
        anchor="right"
        open={props.paymentDrawerOpen}
        onClose={() => props.setPaymentDrawerOpen(false)}
        sx={{ position: "relative"}}
      >
        <Box sx={componentStyles.componentBody}>
          <Box className="flex-row" sx={componentStyles.headerContainer}>
            <Typography variant="h5" sx={componentStyles.title}>
              Make a Payment
            </Typography>

            <Box
              className="col-6"
              sx={componentStyles.closeDrawerButton}
              role="button"
              onClick={handleClosePaymentDrawer}
            >
              <CloseIcon sx={{ height: "34px" }} />
            </Box>
          </Box>

          <div className="flex-row" style={componentStyles.interactionBody}>
            <div className="col-12" style={{ marginBottom: "20px" }}>
              <TextField
                fullWidth
                required
                select
                sx={{
                  "&.MuiOutlinedInput-root.Mui-focused > fieldset": {
                    border: "2px solid #195ffb",
                  },
                }}
                variant="outlined"
                value={selectedPaymentTransactionType}
                onChange={paymentTransactionTypeChangeHandler}
                label="Payment Type"
                InputLabelProps={{ shrink: true }}
                disabled={true}
              >
                {paymentTransactionTypes?.length > 0 &&
                  paymentTransactionTypes.map((paymentTransactionType) => {
                    let name =
                      paymentTransactionType.name &&
                      paymentTransactionType.name.toUpperCase();
                    if (name == "PAYOUT" || name == "PAYMENT") {
                      return (
                        <MenuItem
                          key={paymentTransactionType.name}
                          value={paymentTransactionType.id}
                          disabled={paymentTransactionType?.name === "PAYOUT"}
                        >
                          {paymentTransactionType.name &&
                            paymentTransactionType.name.toUpperCase()}
                        </MenuItem>
                      );
                    }
                  })}
              </TextField>
            </div>
            
            {/* Make a Payment */}
            {selectedPaymentTransactionType == 1 && (
              <PaymentInputs
                {...props}
                currentDate={currentDate}
                dateMinObj={dateMinObj}
                dateMaxObj={dateMaxObj}
                includeTransactionFee={includeTransactionFee}
                setIncludeTransactionFee={setIncludeTransactionFee}
                newPaymentObj={newPaymentObj}
                setNewPaymentObj={setNewPaymentObj}
                setSelectedBankAccount={setSelectedBankAccount}
                setIsCustomDate={setIsCustomDate}
                isCustomDate={isCustomDate}
                determineTransFee={determineTransFee}
              />
            )}
             {/* Make a Payout */}
            {selectedPaymentTransactionType == 4 && (
              <PayoutInputs
                {...props}
                newPayoutObj={newPayoutObj}
                setNewPayoutObj={setNewPayoutObj}
              />
            )}
          </div>

          {/* <Typography variant="body2" sx={componentStyles.description}>
            Saved Payment Methods
          </Typography> */}

          {/* Accounts */}
          <div
            className="col-12"
            style={{ padding: "0px 12px" }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: 500,
                padding: "0 20px",
              }}
            >
              {selectedPaymentTransactionType == 1 && "Payment Method"}
              {selectedPaymentTransactionType == 4 && "Recipient Account"}
            </Typography>
            {selectedPaymentTransactionType == 1 && (newPaymentObj?.paymentDate && dateObjFirstPaySchedule ? formatDateObjYYYYMMDD(newPaymentObj?.paymentDate) == formatDateObjYYYYMMDD(dateObjFirstPaySchedule) : null )&& (
              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                  fontSize: "12px !important",
                  lineHeight: "14px",
                  padding: "10px 20px 4px",
                }}
              >
                Next scheduled transaction is selected, payment will
                automatically come from the default account.
              </Typography>
             )}

            <PaymentBankAccounts
              bankAccount={props.bankAccount}
              member={props.member}
              getMemberAccountType={getMemberAccountType}
              showSelectBankAccount={props.showSelectBankAccount}
              selectedBankAccount={selectedBankAccount}
              handleBankAccountSelect={handleBankAccountSelect}
              isCustomDate={isCustomDate}
              editWalletFromPlans={props.editWalletFromPlans}
            />
          </div>

          <Box sx={{margin:'0 auto', width: '88%',}}>

              {/* Existing Payments on Same date as selected OTP date */}
              <Box sx={componentStyles.amountBreakdownRows}>
                <Typography variant='body2'    
                  sx={componentStyles.totalAmountText} 
                  >{'Existing Payment(s)'}
                </Typography>
                <Typography variant='body2'  
                     sx={{...componentStyles.totalAmountText, fontWeight: 500}}
                      > 
                  {props.UsdFormatterDec.format(totalExistingPayments)}
                </Typography>
              </Box>

               {/* One Time Payment Subtotal */}
               <Box sx={componentStyles.amountBreakdownRows}>
                <Typography variant='body2'    
                  sx={componentStyles.totalAmountText} 
                  >One-Time Payment
                </Typography>
                <Typography variant='body2'  
                     sx={{...componentStyles.totalAmountText, fontWeight: 500}}
                      > 
                  {newPaymentObj?.paymentAmount ? props.UsdFormatterDec.format(newPaymentObj.paymentAmount) : props.UsdFormatterDec.format(0)}
                </Typography>
              </Box>

                {/* Total Amount */}
              <Box sx={{...componentStyles.amountBreakdownRows, padding: '7px 15px'}}>
                <Box sx={componentStyles.totalAmountTextContainer}>
                  <Typography variant='body2'    
                    sx={{...componentStyles.totalAmountText, fontWeight: 700}}
                    >Total
                  </Typography>
                  <Typography variant='body2' sx={componentStyles.transFeeMessage}>
                    $1 Transaction fee included
                  </Typography>
                </Box>

                  <Typography variant='body2'sx={{...componentStyles.totalAmountText, fontWeight: 700}}> 
                    {/* // total of all existing payments, trans fee from scheduled payment if a scheduled payment date is selected OR trans fee for non-scheduled payment date, and new payment amount */}
                    {newPaymentObj?.paymentAmount ? props.UsdFormatterDec.format(newPaymentObj.paymentAmount + totalExistingPayments + transactionFee + includeTransactionFee?.schedulePaymentFee) : props.UsdFormatterDec.format(0)}
                  </Typography>
              </Box>

                {/* trans fee messages */}
              <Box sx={{padding: '15px 0px 0px 15px', width: '88%',}}>
                  {/* message if rerun payment and -$1 trans fee */}
                {props?.rerunPaymentAmount > 0 && 
                <Typography sx={{fontSize: "12px!important", color: 'text.secondary'}}>* $1 less if scheduled payment date selected for Rerun Payment</Typography>}

                {/* {transactionFee > 0 && <Typography sx={{fontSize: "12px!important", color: 'text.secondary'}}>* Date selected outside of payment schedule and trans fee applied.</Typography>} */}
              </Box>
              

          </Box>

          <Box
            className="col-12"
            sx={{
              ...componentStyles.interactionBody,
              display: "flex",
              justifyContent: "space-around",
              backgroundColor: "background.default",
              paddingBottom: "20px",
            }}
          >
            <Button onClick={handleCancelClick}>CANCEL</Button>
            {/* Disable Submit button if balance is $0 or custom amount entered is greater than balance*/}
            <Button
              variant="contained"
              onClick={() =>
                selectedPaymentTransactionType == 1
                  ? handleSchedulePaymentClick()
                  : handleIssuePayoutClick()
              }
              disabled={
                (selectedPaymentTransactionType == 1 &&
                  newPaymentObj?.paymentAmount <= 0) ||
                (selectedPaymentTransactionType == 4 &&
                  newPayoutObj.paymentAmount == 0)
                // || customAmountError
              }
            >
              {loading && (
                <ButtonInfinityLoader buttonTitle={"SCHEDULE PAYMENT"} />
              )}
              {!loading && selectedPaymentTransactionType == 1 && "SCHEDULE PAYMENT"}
              {!loading && selectedPaymentTransactionType == 4 && "ISSUE PAYOUT"}
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
