import "./SignatureModal.css";

import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";

//Assets
import HandSignSVG from "../../../../assets/images/HandSign.svg";

//Services
import enrollmentService from "../../../../services/enrollment-service";

//MuiComponents
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

//MuiIcons
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { Alert } from "@mui/material";


//Our Components
import { formatUTCToMonthDay } from "../../../utils";

function WrapperComponent (props) {
  if (window.innerWidth < 800) {
    return (
      <Drawer
      // {...props}
      open={props.open}
      onClose={props.handleClose}
      anchor="bottom"
      sx={{ maxHeight: "200px", zIndex: "4" }}
      PaperProps={{
        sx: {
          borderRadius: "12px 12px 0px 0px",
          padding: "16px 24px",
          bgcolor: "background.default",
          color: "text.primary",
        },
      }}
      disableScrollLock={true}
      >
        {props.children}
      </Drawer>
    )
  } else {
    return (
      <Modal
      open={props.open}
      onClose={props.handleClose}
      onClick={e=>e.preventDefault()}
      // keepMounted
      sx={{ 
        position: "absolute",
        borderRadius: "12px 12px 12px 12px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

      }}
      container={props.container || document.body}
      hideBackdrop={props.agentEnroller ?true : false}
      disableScrollLock={true}
      >
        <Paper
            sx={{
              borderRadius: "12px 12px 12px 12px",
              padding: "16px 24px",
              bgcolor: "background.default",
              color: "text.primary",
              minWidth:'425px', minHeight:'152px'
            }}
          >
          <div style={{zIndex:'-4',position:'absolute',height:'100%', width:'100%', backgroundColor:'rgba(0, 0, 0, 0.5)',top:0,left:0}}/>
          {props.children}
        </Paper>
      </Modal>
    )
  }
}

export default function SignatureModal(props) {
  let today = new Date();
  const history = useHistory();

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    if (!mounted) setMounted(true);
  }, []);

  //Modal Logic and style
  // const [open, setOpen] = useState(false);
  // const handleOpen = () => {
  //     if (enrollmentStateId != 1){
  //         setOpen(true)
  //     } else {
  //         alert("Already open!")
  //     }
  // };
  // const handleOpen = () =>setOpen(true);
  // const handleClose = () => setOpen(false);

  //Signature logic
  const sigCanvas = useRef({});
  const [sigState, setSigState] = useState({
    fullName: "",
    prefillSig: "",
    signature: "",
    accept: true,
    working: false,
    priorDisability: false,
  });

  // useEffect(() => {
  //   if (props.fullMemberObj){
  //     let member = props.fullMemberObj
  //     setSigState({
  //       ...sigState,
  //       fullName: `${member.firstName} ${member.lastName}`
  //     })
  //   }
  // },[props.fullMemberObj])

  // prefilling the sig area
  useEffect(() => {
    if (mounted && props.open && sigCanvas.current && sigState.prefillSig) {
      clearSignature();
      let parent = document.getElementById("sig-canvas-parent");
      let canvas = sigCanvas.current.getCanvas();
      let ctx = canvas.getContext("2d", { willReadFrequently: true });
      ctx.font = "italic 80px Brush Script MT, cursive";
      // ctx.fillText(sigState.prefillSig, 20, canvas.height - 10, canvas.width-30);
      ctx.fillText(
        sigState.prefillSig,
        20,
        parent.offsetHeight - 40,
        parent.offsetWidth - 30
      );
      // console.log('CTX',ctx)
      // let sigUrl = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
      // console.log('sigurl BORERERE',sigUrl)
      // setSigState({
      //     ...sigState,
      //     signature: sigUrl,
      // })
    }
  }, [sigState.prefillSig]);

  const clearSignature = async () => {
    if (sigCanvas.current) {
        sigCanvas.current.clear();
        setSigState({
            ...sigState,
            signature: "",
        });
    }
  };
  const clearPrefill = (e) => {
    let canvas = sigCanvas.current.getCanvas();
    let ctx = canvas.getContext("2d", { willReadFrequently: true });
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    setSigState({
      ...sigState,
      prefillSig:''
    })
  };

  const handleClearClick = async () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
      setSigState({
          ...sigState,
          signature: "",
          fullName: ""
      });
    }
  }

  //if a user typed and populated the signature with a typed sig, AND then tries to also sign with mouse/finger, clear canvas, clear text input, and they start signing on a blank canvas
  const clearCanvasOfTextFromTyping = (e) => {
    //clear text input and associated state value
    if (e.preventDefault) e.preventDefault()
    if (sigState.prefillSig?.length>0){
      clearPrefill()
    }
  };

  const signatureHandler = async (e) => {
    if (sigCanvas.current) {
      let sigUrl = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
      setSigState({
        ...sigState,
        signature: sigUrl,
      });
    }
  };

  const handlePrefillBlur = () => {
    if (sigCanvas.current) {
      let sigUrl = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
      // console.log('sigurl BORERERE',sigUrl)
      setSigState({
        ...sigState,
        signature: sigUrl,
      });
    }
  };

  const sigNameHandler = (e) => {
    if(e.target.value == ""){
      clearSignature()
    }else{
      setSigState({
        ...sigState,
        fullName: e.target.value,
        prefillSig: e.target.value,
      });
    }
    setSigState({
      ...sigState,
      fullName: e.target.value,
      prefillSig: e.target.value,
    });

  };
  const openEnrollment = async () => {
    let signatureObj = {
      enrollmentId: props.enrollmentId,
      signatureText: sigState.fullName,
      signature: sigState.signature,
      signatureDate: props.paper?.isMail
        ? props.paper.signatureDate.toLocaleString().slice(0, 9)
        : today.toLocaleString().slice(0, 9),
      groupPlanId: props.results.groupPlanId,
      isMail: props.paper?.isMail ? props.paper.isMail : false,
      isVerbal: props.verbalAuth,
    };
    if (props.agentEnroller) {
      await enrollmentService.openEnrollmentForMember(
        signatureObj,
        props.fullMemberObj.id
      );
      // props.setOpenEnrollment(true);
      // props.setTabValue(4);
    } else {
      await enrollmentService.openEnrollment(signatureObj);
    }
    // props.setEnrollmentSelected()
    props.handleClose();
  };

  const submitEnrollment = async () => {
    let fullName;
    if (!sigState.fullName){
      let member = props.fullMemberObj
      fullName = `${member.firstName} ${member.lastName}`
    } else {
      fullName = sigState.fullName
    }
    let signatureObj = {
      enrollmentId: props.enrollmentId,
      signatureText: fullName,
      signature: sigState.signature,
      signatureDate: props.paper.isMail
        ? new Date(props.paper.signatureDate).toJSON().slice(0,10)
        : today.toJSON().slice(0,10),
      groupPlanId: props?.results?.groupPlanId,
      isMail: props.paper.isMail,
      isVerbal: props.verbalAuth,
      workingAcknowledgement: sigState.working,
      priorDisabilityAcknowledgement: sigState.priorDisability,
      workStatusId: props?.fullMemberObj?.workStatusId,
      nawReason: props?.fullMemberObj?.workStatusId == 20 ? props?.nawReason : props.fullMemberObj.workStatus
    };
    let enrollmentResponse;
    if (props.agentEnroller) {
      enrollmentResponse = await enrollmentService.submitEnrollmentForMember(
        signatureObj,
        props.fullMemberObj.id
      );
    } else {
      console.log("signature object after submit enrollment", signatureObj);
      enrollmentResponse = await enrollmentService.submitEnrollment(
        signatureObj
      );
    }
    console.log("enrollmentResponse", enrollmentResponse);
    props.setGuid(enrollmentResponse);
    props.handleClose();
    // props.setEnrollmentSelected()
    // props.displayPay(sigState.signature)
  };

  const signClick = async () => {
    if (props.setLoading) props.setLoading(true)
    if (props.signClick == "Reopen" || props.signClick == "CancelIsReopen") {
      await openEnrollment();
      if (props.agentEnroller) {
        props.refreshEnrollment(props.results);
        props.setSigModalOpen(false);
        if (props.setActivePage) props.setActivePage(4)
      } else {
        history.push("calculator", { ...history.location.state });
      }
    } else if (props.signClick == "Cancel") {
      //we currently do not hit this when canceling a submitted enrollment during open enrollment
      // await enrollmentService.cancelEnrollment(props.enrollmentId);
      // if (props.agentEnroller) {
      //   props.refreshEnrollment(props.results);
      //   props.setSigModalOpen(false);
      // }
    } else if (props.signClick == "Submit") {
      await submitEnrollment();
      if (props.agentEnroller) {
        props.setLoading(false)
        props.refreshEnrollment(props.results);
        props.setAgentEnrollmentPageState({ complete: true });
        props.getThisMember()
        props?.getPaymentSchedule()
      } else {
        props.setLoading(false)
        history.push("success", { ...history.location.state });
      }
      localStorage.clear("recommendations");
      localStorage.clear("laddDecline");
    }
  };

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (!props.agentEnroller) {
      if (
        (sigState.fullName !== "" || sigState.signature !== "") &&
        sigState.accept == true 
        && sigState.working == true
        && sigState.priorDisability == true
        // && props.fullMemberObj.workStatusId == 1
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(false);
    }
  }, [sigState.fullName, sigState.signature, sigState.accept, sigState.working, sigState.priorDisability]);

  // console.log('sig modal props',props)
  const [container,setContainer] = useState(null)
  useEffect(()=>{ 
    if (props.open){
      if (props.agentEnroller) {
        let _container = document.getElementsByClassName('member-router-container')[0]
        setContainer(_container)
      }
    }
  },[props.open, props.agentEnroller])

  let effectiveDate = props.results?.coverageEffectiveDate ? formatUTCToMonthDay(props.results.coverageEffectiveDate) : "july 1"
  console.log('props results',props.results)
  return (
    <>
      <WrapperComponent {...props} container={container}>
        <Box
          sx={{
            bgcolor: "paper.background.default",
            color: "text.primary",
            maxWidth: "600px",
            margin: "auto",
          }}
        >
          <div
            className="row"
            style={{ marginBottom: "0px", justifyContent: "space-between" }}
          >
            <Typography className="col-10" variant="blueAccent">
              Authorization
            </Typography>
            <IconButton onClick={props.handleClose} className="col-1" sx={{}}>
              <CloseIcon sx={{ color: "#195ffb" }} />
            </IconButton>
          </div>
          {!props.agentEnroller ? (
            <>
              {/* /////////////////////////////////////// */}
              <div className="col-9" style={{ marginBottom: "20px" }}>
                <Typography variant="body1" sx={{fontSize:'14px', letterSpacing:'.17px'}}>
                  To complete your enrollment application please acknowledge and sign below.
                </Typography>
              </div>

              <div
                className="flex-row container"
                style={{
                  padding: "0px 0px 0px 0vw",
                  position: "relative",
                  marginBottom: "16px",
                }}
              >
                <Checkbox
                  onClick={() =>
                    setSigState({
                      ...sigState,
                      working: !sigState.working,
                    })
                  }
                  value={sigState.working}
                  checked={sigState.working}
                  // name={1}
                  sx={{ display: 'flex', alignSelf: 'flex-start', marginLeft: 'auto', marginRight: 'auto', height: '50%' }}
                  inputProps={{
                      'data-uhtest': 'employedAndActivelyWorking_checkbox'
                  }}
                  // TouchRippleProps={{sx:{display:'flex',alignSelf:'center', marginLeft:'auto', marginRight:'auto', backgroundColor:'red', height:'50%' }}}
                  icon={<CheckBoxOutlineBlankIcon sx={{ height: "19px", color:'#195ffb' }} />}
                  checkedIcon={<CheckBoxIcon sx={{ height: "19px" }} />}
                />

                <Typography variant="body1" sx={{ margin: "auto 0", fontSize:'15px', letterSpacing:'.15px' }}>
                  I acknowledge that to be covered on the effective date [{effectiveDate}], I must be employed and actively working. I understand that if my work status and/or union status changes, I may not be covered.
                </Typography>
              </div>

              <div
                className="flex-row container"
                style={{
                  padding: "0px 0px 0px 0vw",
                  position: "relative",
                  marginBottom: "16px",
                }}
              >
                <Checkbox
                  onClick={() =>
                    setSigState({
                      ...sigState,
                      priorDisability: !sigState.priorDisability,
                    })
                  }
                  value={sigState.priorDisability}
                  checked={sigState.priorDisability}
                  // name={1}
                  sx={{ display: 'flex', alignSelf: 'flex-start' }}
                  inputProps={{
                      'data-uhtest': 'disabilitiesAfterCoverage_checkbox'
                  }}
                  icon={<CheckBoxOutlineBlankIcon sx={{ height: "19px", color:'#195ffb' }} />}
                  checkedIcon={<CheckBoxIcon sx={{ height: "19px" }} />}
                />

                <Typography variant="body1" sx={{ margin: "auto 0", fontSize:'15px', letterSpacing:'.15px' }}>
                  I understand that only disabilities starting after the coverage effective date are eligible for benefits, subject to the pre-existing condition limitation. Disabilities starting before the coverage effective date are not covered.
                </Typography>
              </div>

              <TextField
                onChange={sigNameHandler}
                onBlur={handlePrefillBlur}
                className="col-12"
                value={sigState.fullName}
                label="Type signature"
                sx={{ marginBottom: "15px" }}
                inputProps={{
                  'data-uhtest':'signature_textfield'
                }}
                // InputLabelProps={{ shrink: true }}
              />

              <Divider
                sx={{
                  color: "#989898",
                  fontWeight: 700,
                  fontSize: "15px",
                  fontFamily: "Poppins",
                  maxWidth: "900px ",
                  margin: "auto",
                  marginLeft: "40px",
                  marginRight: "40px",
                  color: "#646464",
                  ":before, :after": {
                    top: "0%",
                    borderTop: "2px solid #d3d3d3",
                  },
                }}
              >
                Or
              </Divider>

              <div
                style={{ position: "relative", marginBottom: "25px" }}
                id="sig-canvas-parent"
              >
                <SignatureCanvas
                  ref={sigCanvas}
                  onClose={clearSignature}
                  onEnd={signatureHandler}
                  onBegin={clearCanvasOfTextFromTyping}
                  canvasProps={{
                    className: "col-12",
                    height: 300,
                    style: {
                      border: "1px solid #0000003B",
                      borderRadius: "8px",
                      marginTop: "13px",
                    },
                  }}
                />

                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    bottom: ".5pc",
                    zIndex: "-1",
                    padding: "0px 8px",
                  }}
                  className="flex-row"
                >
                  <hr
                    className="col-12"
                    style={{
                      // borderTop:'dashed 1px black',
                      backgroundColor: "transparent",
                      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='1%2c10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                    }}
                  />
                </div>

                <Button
                  style={{
                    position: "absolute",
                    width: "20px",
                    bottom: "50%",
                    right: "4%",
                    textAlign: "end",
                    fontFamily: "Archivo",
                    fontWeight: 600,
                    fontSize: "11px",
                    letterSpacing: ".4px",
                    color: "#00000099",
                  }}
                  onClick={handleClearClick}
                >
                  Clear
                </Button>

                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    bottom: "32%",
                    left: "4%",
                    zIndex: "-1",
                  }}
                >
                  {sigState.fullName?.length>0 || !sigState.signature &&
                    <img src={HandSignSVG} style={{ height: "35px" }} />  
                  }
                </div>
              </div>

              <div
                className="flex-row container"
                style={{
                  padding: "0px 0px 0px 0vw",
                  position: "relative",
                  marginBottom: "16px",
                }}
              >
                <Checkbox
                  onClick={() =>
                    setSigState({
                      ...sigState,
                      accept: !sigState.accept,
                    })
                  }
                  value={sigState.accept}
                  checked={sigState.accept}
                  // name={1}
                  sx={{}}
                  icon={<CheckBoxOutlineBlankIcon sx={{ height: "19px", color:'#195ffb' }} />}
                  checkedIcon={<CheckBoxIcon sx={{ height: "19px" }} />}
                />

                <Typography variant="body1" style={{ margin: "auto 0" }}>
                  I understand this is a legal representation of my signature.
                </Typography>
              </div>
              {/* /////////////////////////////////////// */}
            </>
          ) : (
            <>
              {props?.signClick == "Submit" && (props?.fullMemberObj?.email == null || props?.fullMemberObj?.email == undefined || props?.fullMemberObj?.email == '') &&
                <div className="col-12" style={{ marginBottom: "20px",textAlign:'center' }}>
                  <Alert severity="error">
                    Member is missing email address. Please add email address to member's personal information before submitting enrollment.
                  </Alert>
                </div>
              }
            
                <Typography variant="body1" sx={{margin: '0 auto 20px', textAlign: 'center',}}>
                  {props.signClick == "Reopen" &&
                    'To reopen this enrollment application, click "Reopen Enrollment".'}
                  {props.signClick == "CancelIsReopen" &&
                    'To cancel this enrollment application, click "Cancel Enrollment". Application will be moved back to an "In Progress" state and can be submitted again if this was a mistake. This is no longer submitted and payments will not be processed.'}
                  {props.signClick == "Cancel" &&
                    'To Cancel this enrollment application, click "Cancel Enrollment".'}
                  {props.signClick == "Submit" &&
                    'To submit this enrollment application, click "Enroll".'}
                </Typography>
             
            </>
          )}



          <Button
            onClick={signClick}
            disabled={disabled || (props?.signClick == "Submit" && (props?.fullMemberObj?.email == null || props?.fullMemberObj?.email == undefined || props?.fullMemberObj?.email == ''))}
            variant="contained"
            className="row"
            fullWidth
            sx={{ height: "42px", marginBottom: "35px" }}
          >
            {/* {props.signClick == 'Reopen' ? 'Reopen enrollment' : 'Enroll'} */}
            {props.signClick == "Reopen" && "Reopen Enrollment"}
            {(props.signClick == "Cancel" || props.signClick == "CancelIsReopen") && "Cancel Enrollment"}
            {props.signClick == "Submit" && "Enroll"}
          </Button>
        </Box>
      </WrapperComponent>
    </>
  )
}
