import React, { useEffect, useState, useContext, useRef } from "react"
import { useHistory } from "react-router-dom"
import { format, formatDistance, addDays, addYears } from "date-fns"

//MuiX
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"

//services
import ticketService from "../../../../services/ticket-service"
import optionService from "../../../../services/optionService"
import userService from "../../../../services/user-service"

//Mui Components
import Checkbox from "@mui/material/Checkbox"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import FormGroup from "@mui/material/FormGroup"
import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import Button from "@mui/material/Button"
import Snackbar from "@mui/material/Snackbar"
import MuiAlert from "@mui/material/Alert"

import FormControlLabel from "@mui/material/FormControlLabel"
import Box from "@mui/material/Box"
import SvgIcon from "@mui/material/SvgIcon"
import Avatar from "@mui/material/Avatar"
import { Typography } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import Autocomplete from "@mui/material/Autocomplete"
import Stack from "@mui/material/Stack"

// Assets
import openSvg from "../../../../assets/images/openSvg.svg"
import closedSvg from "../../../../assets/images/closedSvg.svg"

//Mui icons
import FilterList from "@mui/icons-material/FilterList"
import ManageSearchIcon from "@mui/icons-material/ManageSearch"
import SyncIcon from "@mui/icons-material/Sync"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

//Our Components
import OurDataGrid from "../../DataGrid/OurDataGrid"
import CreateTicketDrawer from "../../Member/member-profile/MemberTabs/member-tickets-tab/CreateTicketDrawer"
import { thisUserContext } from "../../nav-container/NavContainer"
import { useGridApiRef } from "@mui/x-data-grid"
import TicketPriorityChips from "../../Member/DisplayChips/TicketPriorityChips"
import { Chip } from "@mui/material"

//utils
import {
  formatDateObjMMDDYYYYDisplay,
  formatDateObjYYYYMMDD,
  formatUTCDateToObjWithTimeZone,
} from "../../../utils"

export default function TicketOverview(props) {
  const apiRef = useGridApiRef()

  const [tickets, setTickets] = useState([])
  const [origins, setOrigins] = useState(null)
  const [types, setTypes] = useState(null)
  const [priorities, setPriorities] = useState(null)
  const [SLAs, setSLAs] = useState(null)
  const [selectedList, setSelectedList] = useState("")
  const [account, setAccount] = useState(null)
  const [thisUser, setThisUser] = useState()
  const [checks, setChecks] = useState({ open: "", closed: "", system: "" })

  // const [filterModel, setFilterModel] = React.useState({
  //    items: [
  //        {
  //           columnField: 'status',
  //           operatorValue: 'contains',
  //           value: Object.entries(checks).find(item => item[1] == true)[0],
  //        },
  //    ],
  // });
  useEffect(() => {
    // setFilterModel({
    //    items: [
    //       {
    //          columnField: 'status',
    //          operatorValue: 'contains',
    //          value: Object.entries(checks).find(item => item[1] == true)[0],
    //       },
    //   ],
    // })
    buildRows()
    getGridCols()
  }, [checks])

  const [allUsers, setAllUsers] = useState([])
  let _thisUser = useContext(thisUserContext)
  let getThisUser = async () => {
    setThisUser(_thisUser.thisUser)
    setAllUsers(_thisUser.allUsers)
  }
  useEffect(() => {
    if (_thisUser) getThisUser()
  }, [_thisUser])
  //console.log(tickets)

  const getTickets = async () => {
    setLoadingState(true)
    let _tickets = []
    // if (tickets?.length == 0) {
    _tickets = await ticketService.getAllTickets()

    // }
    if (!origins) {
      let origins = await optionService.getTicketOrigins()
      setOrigins(origins)
    }
    if (!types) {
      let types = await optionService.getTicketTypes()
      setTypes(types)
    }
    if (!priorities) {
      let priorities = await optionService.getTicketPriority()
      setPriorities(priorities)
    }
    if (!SLAs) {
      let SLAs = await optionService.getTicketSLA()
      setSLAs(SLAs)
    }
    switch (selectedList) {
      case "My Tickets":
        setTickets(_tickets.filter((ticket) => ticket?.userId == thisUser?.id))
        setLoadingState(false)
        break
      case "All Tickets":
        setTickets(_tickets)
        setLoadingState(false)
        break
      default:
        console.log("no ticket list")
    }
  }

  const [loadingState, setLoadingState] = useState(false)
  const refreshTickets = async () => {
    setLoadingState(true)
    let tickets = await ticketService.getAllTickets()

    switch (selectedList) {
      // case "All Tickets":
      //    setTickets(tickets.filter(ticket => ticket?.memberId == props.member?.id));
      //    break;
      case "My Tickets":
        setTickets(tickets.filter((ticket) => ticket?.userId == thisUser?.id))
        setLoadingState(false)
        break
      case "All Tickets":
        setTickets(tickets)
        setLoadingState(false)
        break
      default:
        console.log("no ticket list")
    }
    setLoadingState(false)
  }

  // Event Handlers

  const handleChangeSelectedList = (event) => {
    setLoadingState(true)
    setSelectedList(event?.target?.value)
    setLoadingState(false)
    setTicketFilters({
      ...ticketFilters,
      selectedList: event?.target?.value,
    })
  }

  const handleCheckBox = (event) => {
    let key = event.target.value
    let value = event.target.checked
    setChecks({ ...checks, [key]: value })
    setTicketFilters({
      ...ticketFilters,
      [key]: value,
    })
  }

  useEffect(() => {
    if (thisUser && selectedList) {
      switch (selectedList) {
        case "My Tickets":
          break
        case "All Tickets":

        default:
          break
      }
      getTickets()
    }
  }, [thisUser, selectedList])

  useEffect(() => {
    if (tickets && origins) {
      buildRows()
    }
  }, [tickets, origins])

  //NEW TICKET DRAWER
  const [ticketDrawerOpen, setTicketDrawerOpen] = useState(false)

  const getStatusIcon = (statusId) => {
    switch (statusId) {
      case 1:
        let openStatus = {
          value: "open",
          image: openSvg,
        }
        return openStatus
        break
      case 2:
        let closedStatus = {
          value: "closed",
          image: closedSvg,
        }
        return closedStatus
        break
    }
  }

  // This state object determines which columns are hidden on page load.  The default setting if the user has no past interactions is below.  The name in the grid col MUST match the "Field" property within the row definitions in each individual grid.
  const [gridCols, setGridCols] = useState({})

  // Returns all filters from local storage or builds new
  const getGridCols = () => {
    let _gridCols = localStorage.getItem("ticketGridCols")
    if (_gridCols) {
      console.log(_gridCols)
      _gridCols = JSON.parse(_gridCols)
      setGridCols(_gridCols)
    } else {
      setGridCols({
        id: false,
        status: true,
        organization: true,
        uniqueId: true,
        requester: true,
        subject: true,
        assignee: true,
        lastUpdated: true,
        origin: true,
        priority: true,
        dueDate: true,
        ticketTypeId: true,
      })
    }
  }

  // Updates local storage with current settings
  const saveGridCols = () => {
    localStorage.setItem("ticketGridCols", JSON.stringify(gridCols))
    getGridCols()
  }

  useEffect(() => {
    if (Object.keys(gridCols).length != 0) {
      console.log("changes")
      saveGridCols()
    }
  }, [JSON.stringify(gridCols)])

  const [rows, setRows] = useState([])
  const buildRows = () => {
    setLoadingState(true)
    let _rows = []

    let filterCheck = []
    for (let [key, val] of Object.entries(checks)) {
      if (val === true) filterCheck.push(key)
    }

    tickets.forEach((ticket) => {
      if (filterCheck.length > 0) {
        if (filterCheck.includes(getStatusIcon(ticket.ticketStatusId)?.value)) {
          let builtRow = {
            id: ticket.id,
            status: getStatusIcon(ticket.ticketStatusId)?.value,
            organization: ticket.organization,
            uniqueId: ticket.uniqueId,
            requester: ticket.memberDisplayName,
            subject: ticket.subject,
            assignee: ticket.userId,
            lastUpdated: ticket.lastModifiedDate,
            origin: origins.find((origin) => origin.id == ticket.ticketOriginId)
              ?.name,
            priority: ticket.ticketPriorityId,
            dueDate: ticket.dueDate
              ? formatUTCDateToObjWithTimeZone(ticket.dueDate)
              : null, //convert to date obj
            ticketTypeId: ticket.ticketTypeId,
          }
          _rows.push(builtRow)
        }
      } else {
        let builtRow = {
          id: ticket.id,
          status: getStatusIcon(ticket.ticketStatusId)?.value,
          organization: ticket.organization,
          uniqueId: ticket.uniqueId,
          requester: ticket.memberDisplayName,
          subject: ticket.subject,
          assignee: ticket.userId,
          lastUpdated: ticket.lastModifiedDate,
          origin: origins.find((origin) => origin.id == ticket.ticketOriginId)
            ?.name,
          priority: ticket.ticketPriorityId,
          dueDate: ticket.dueDate
            ? formatUTCDateToObjWithTimeZone(ticket.dueDate)
            : null, //convert to date obj
          ticketTypeId: ticket.ticketTypeId,
        }
        _rows.push(builtRow)
      }
    })
    setRows(_rows)

    setLoadingState(false)
  }

  //THIS OVERRIDES THE NO ROWS DATAGRID COMPONENT
  function NoTicketRows(props) {
    return (
      <div style={{ backgroundColor: "", height: "100%", textAlign: "center" }}>
        <Box
          sx={{ backgroundColor: "primary.light" }}
          style={{
            margin: "54px auto 13px",
            width: "58px",
            height: "58px",
            borderRadius: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ManageSearchIcon
            sx={{ width: "28px", height: "28px", color: "primary.main" }}
          />
        </Box>
        <div style={{ marginBottom: "19px" }}>
          No tickets currently assigned here
        </div>

        {/* <div>
               <Button variant='contained'
                  sx={{ height: 40, minWidth: 200, borderRadius: 100, width: '152px', fontWeight: '500!important', zIndex: 5 }}
                  // startIcon={<AddIcon/>}
                  onClick={() => setTicketDrawerOpen(true)}
               >
                  Open New Ticket
               </Button>
            </div> */}
      </div>
    )
  }

  //THIS OVERRIDES THE NO RESULTS DATAGRID COMPONENT
  function NoTicketResults(props) {
    return (
      <div style={{ backgroundColor: "", height: "100%", textAlign: "center" }}>
        <Box
          sx={{ backgroundColor: "primary.light" }}
          style={{
            margin: "54px auto 13px",
            width: "58px",
            height: "58px",
            borderRadius: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ManageSearchIcon
            sx={{ width: "28px", height: "28px", color: "primary.main" }}
          />
        </Box>
        <div style={{ marginBottom: "19px" }}>No tickets found</div>

        {/* <div>
               <Button variant='contained'
                  sx={{ height: 40, minWidth: 200, borderRadius: 100, width: '152px', fontWeight: '500!important', zIndex: 5 }}
                  // startIcon={<AddIcon/>}
                  onClick={() => setTicketDrawerOpen(true)}
               >
                  Open New Ticket
               </Button>
            </div> */}
      </div>
    )
  }

  const handleRowClick = (params, e) => {
    console.log(e.target, params)
    let clickedCell = e.target
    let fieldName = clickedCell.getAttribute("data-field")
    if (fieldName == "assignee" || fieldName == "dueDate") {
      console.log("allow edit instead of push")
    } else history.push(`tickets/${params.row.id}`)
  }

  let dateMinObj = new Date()
  let dateMaxObj = addYears(new Date(), 1)

  // CHANGE DUE DATE INLINE EDIT
  const [ticketToChangeDueDate, setTicketToChangeDueDate] = useState({})
  const [datePickerClose, setDatePickerClose] = useState(true)

  //clear ticketToChange when clicking into datePicker
  const handleDueDateOpen = () => {
    setTicketToChangeDueDate({})
  }
  //set local state ticket to change so we only update single ticket due date that is chosen from grid
  const handleDueDateChange = (newDate, params) => {
    let ticket = tickets.find((ticket) => ticket.id === params.id)

    //check requester value and make sure it's not null in ticket object when updating due date (important for legacy tickets)
    let _requester = ticket?.requester
    if (!_requester) _requester = ""
    setLoadingState(true)
    let updatedDueDate = formatDateObjYYYYMMDD(newDate) //ok w/ formatting here to send to db as short date string
    setTicketToChangeDueDate({
      ...ticket,
      dueDate: updatedDueDate,
      requester: _requester,
    })
  }

  //update ticket with new dueDate AFTER datePicker closes
  const handleDueDateBlur = async (e) => {
    //if ticket exists and due date on ticket exist
    if (ticketToChangeDueDate && ticketToChangeDueDate.dueDate) {
      try {
        await ticketService.updateTicket(ticketToChangeDueDate)
        console.log("ticket to update", ticketToChangeDueDate)
      } catch (error) {
        console.error("error updating ticket", error)
      }
    }
    refreshTickets()
  }

  //only update backend w/ new date after datepicker closes using changing value of datePickerClose onClose
  useEffect(() => {
    if (ticketToChangeDueDate.dueDate) {
      handleDueDateBlur() // Call the handler when the ticket or dueDate changes
    }
  }, [datePickerClose])

  //Assignee AutoComplete Logic
  const [open, setOpen] = useState(false)
  const loading = open && allUsers.length === 0
  const [assigneeObj, setAssigneeObj] = useState({})

  const handleAssigneeChange = async (e, newValue, params) => {
    let rowId = params.id
    let ticket = tickets.find((ticket) => ticket.id == params.id)
    setLoadingState(true)
    if (newValue?.id) {
      setAssigneeObj(newValue)
      try {
        await ticketService.assignTicket(ticket.id, newValue.id)
      } catch (error) {
        console.log("error assigning ticket", error)
      }
      refreshTickets()
      let outerClick = document.getElementById("all-tickets-grid")
      if (outerClick) outerClick.click()
    } else {
      setAssigneeObj({})
    }
  }

  //update asignee and clear field after update
  useEffect(() => {
    setAssigneeObj({})
  }, [loadingState])

  const sendUpdatedTicket = async (params, e, ticket) => {
    console.log("cell edit commit", params, e.target, ticket)
    // let _ticket = ticket
    // if (!_ticket) _ticket = tickets.find(ticket => ticket.id == params.id)
    // if (_ticket){
    //    await ticketService.updateTicket(_ticket)
    //    refreshTickets()

    // }
  }

  const searchAdornment = (
    <IconButton
    // onClick={()=>searchAsync()}
    >
      <ManageSearchIcon sx={{ color: "#CCCCCC" }} />
    </IconButton>
  )

  // useEffect(() => {
  //    console.log(tickets)
  //    //build columns and rows
  //    if (origins) {
  //       buildRows()
  //    }
  // }, [tickets, origins])

  let history = useHistory()

  // const defaultOperators = getDefaultGridFilterModel()
  // console.log('defOPER',defaultOperators)

  //CUSTOM DATE RANGE OPERATOR
  function DateRangeInput(props) {
    const { item, applyValue, focusElementRef = null } = props

    const filterTimeout = useRef()
    const [filterValueState, setFilterValueState] = useState(item.value ?? "")

    const [applying, setIsApplying] = useState(false)

    useEffect(() => {
      return () => {
        clearTimeout(filterTimeout.current)
      }
    }, [])

    useEffect(() => {
      const itemValue = item.value ?? [undefined, undefined]
      setFilterValueState(itemValue)
    }, [item.value])

    const updateFilterValue = (lowerBound, upperBound) => {
      clearTimeout(filterTimeout.current)
      setFilterValueState([lowerBound, upperBound])

      setIsApplying(true)
      filterTimeout.current = setTimeout(() => {
        setIsApplying(false)
        applyValue({ ...item, value: [lowerBound, upperBound] })
      }, 500)
    }

    const handleUpperFilterChange = (event) => {
      const newUpperBound = event.target.value
      updateFilterValue(filterValueState[0], newUpperBound)
    }
    const handleLowerFilterChange = (event) => {
      const newLowerBound = event.target.value
      updateFilterValue(newLowerBound, filterValueState[1])
    }

    return (
      <Box
        sx={{
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "end",
          height: 48,
          pl: "4px",
        }}
      >
        <style>
          {`
               .MuiDataGrid-filterFormValueInput {
                  width:220px;
               }
               `}
        </style>
        <TextField
          name="lower-bound-input"
          placeholder="From"
          label="From"
          variant="standard"
          value={filterValueState[0]}
          onChange={handleLowerFilterChange}
          type="date"
          inputRef={focusElementRef}
          sx={{ mr: "8px" }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          name="upper-bound-input"
          placeholder="To"
          label="To"
          variant="standard"
          value={filterValueState[1]}
          onChange={handleUpperFilterChange}
          type="date"
          InputProps={applying ? { endAdornment: <SyncIcon /> } : {}}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
    )
  }

  const quantityOnlyOperators = [
    {
      label: "Between",
      value: "between",
      getApplyFilterFn: (filterItem) => {
        if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
          return null
        }
        if (filterItem.value[0] == null || filterItem.value[1] == null) {
          return null
        }

        return ({ value }) => {
          return (
            value !== null &&
            new Date(filterItem.value[0]) <= new Date(value) &&
            new Date(value) <= addDays(new Date(filterItem.value[1]), 1)
          )
        }
      },
      InputComponent: DateRangeInput,
    },
  ]

  // console.log('types',types)

  const columns = [
    {
      field: "Importance",
      headerName: "",
      width: 60,
      hidden: true,
      align: "center",
      headerClassName: "hide-right-sep hide-left-sep",
      renderCell: (params) => {
        // return <div style={{ backgroundColor: '#F0F0F0', borderRadius: '100px', fontWeight: 700, fontSize: 10, textAlign: 'center', fontFamily: 'Archivo', padding: '2px 13px' }}>1</div>
        // console.log('renderparam',params)
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: " hide-right-sep hide-left-sep",
      renderCell: (params) => (
        <>
          <Box
            sx={{
              height: 24,
              width: 24,
              backgroundColor:
                params.formattedValue === 2 ? "text.secondary" : "primary.main",
              color: "white",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "16px",
            }}
          >
            <Typography textAlign="center" variant="body1" fontWeight="600">
              {params.formattedValue === 2 ? "C" : "O"}
            </Typography>
          </Box>
          <Typography variant="overline">
            {params.formattedValue === 2 ? "Closed" : "Open"}
          </Typography>
        </>
      ),
      valueFormatter: ({ value }) => {
        switch (value) {
          case "open":
            return 1
            break
          case "closed":
            return 2
            break
        }
      },
      type: "string",
      minWidth: 150,
      maxWidth: 200,
      flex: 1,
      // align:'center',
    },
    {
      field: "requester",
      headerName: "Member",
      headerClassName: "hide-right-sep hide-left-sep",
      // renderCell: (params) => (
      // params.value
      // ),
      minWidth: 200,
      flex: 1,
    },
    {
      field: "organization",
      headerName: "Organization",
      headerClassName: "hide-right-sep hide-left-sep",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        // console.log('org in col',params)
      },
    },
    {
      field: "subject",
      headerName: "Subject",
      headerClassName: "hide-right-sep hide-left-sep",
      minWidth: 250,
      flex: 2,
    },
    {
      field: "ticketTypeId",
      headerName: "Ticket Type",
      headerClassName: "hide-right-sep hide-left-sep",
      minWidth: 170,
      flex: 1,
      valueGetter: (params) => {
        return `${
          types && types?.find((type) => type.id == params.value)?.name
        }`
      },
      renderCell: (params) => {
        return (
          <>
            {params.row.ticketTypeId && (
              <>
                <Typography>
                  <span
                    style={{ display: "inline-block", lineBreak: "anywhere" }}
                  >
                    {types &&
                      types?.find((type) => type.id == params.row.ticketTypeId)
                        ?.name}
                  </span>
                </Typography>
              </>
            )}
          </>
        )
      },
    },
    {
      field: "lastUpdated",
      headerName: "Last Updated",
      headerClassName: "hide-right-sep hide-left-sep",
      renderCell: (params) =>
        `${formatDistance(new Date(params.value), new Date())} ago`,
      type: "date",
      minWidth: 170,
      flex: 1,
    },
    {
      field: "origin",
      headerName: "Origin",
      headerClassName: "hide-right-sep hide-left-sep",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "priority",
      headerName: "Priority",
      headerClassName: "hide-right-sep hide-left-sep",
      minWidth: 120,
      flex: 1,
      renderCell: ({ value }) => {
        return <TicketPriorityChips ticketPriority={value} />
      },
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      headerClassName: "hide-right-sep hide-left-sep",
      minWidth: 170,
      flex: 1,
      type: "date",
      filterOperators: quantityOnlyOperators,
      editable: true,
      renderCell: (params) => {
        return (
          <div data-field="dueDate">
            {/* //display date obj in short date string format if DatePicker is not being used */}
            <span data-field="dueDate">
              {params.value ? formatDateObjMMDDYYYYDisplay(params.value) : ""}
            </span>
          </div>
        )
      },
      renderEditCell: (params) => {
        // console.log('dueDate params',params)
        let topParams = params
        return (
          <div data-field="dueDate">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                // disabled={sendTicketLoading}
                // disableFuture
                // openTo="year"
                maxDate={dateMaxObj}
                minDate={dateMinObj}
                views={["year", "month", "day"]}
                value={params.row?.dueDate || null}
                onOpen={handleDueDateOpen}
                onChange={(e) => handleDueDateChange(e, topParams)}
                onClose={() => setDatePickerClose((prevValue) => !prevValue)}
                format="MM/dd/yyyy"
                sx={{
                  width: "100%",
                  padding: "0px",
                  "& .MuiOutlinedInput-input": {
                    padding: "12px 12px",
                    fontSize: "15px", // Adjust the font size
                  },
                  "& fieldset": {
                    height: "50px",
                  },
                }}
              />
            </LocalizationProvider>
          </div>
        )
      },
    },
    {
      field: "uniqueId",
      headerName: "Ticket ID",
      headerClassName: "hide-right-sep hide-left-sep",
      renderCell: (params) => {
        return `${params.row.id}`
      },
      minWidth: 150,
      flex: 1,
      hide: true,
    },
    {
      field: "assignee",
      headerName: "Assignee",
      headerClassName: "center-grid-header hide-right-sep hide-left-sep",
      minWidth: 220,
      flex: 1,
      editable: true,

      valueGetter: (params) => {
        return `${
          allUsers &&
          allUsers?.find((user) => user.id == params.value)?.firstName
        } ${
          allUsers &&
          allUsers?.find((user) => user.id == params.value)?.lastName
        }`
      },
      renderCell: (params) => {
        apiRef.current = params.api
        return (
          <div data-field="assignee">
            {params.row.assignee && (
              <>
                <Tooltip title="Double click to edit">
                  <div
                    className="flex-row"
                    data-field="assignee"
                    style={{ alignItems: "center" }}
                  >
                    <Avatar
                      sx={{
                        bgcolor: "#195FFB17",
                        color: "#195FFB",
                        fontFamily: "Archivo",
                        fontWeight: "600",
                        width: 40,
                        height: 40,
                        fontSize: 18,
                        pointerEvents: "none",
                      }}
                      alt={`${
                        allUsers &&
                        allUsers?.find((user) => user.id == params.row.assignee)
                          ?.firstName
                      } ${
                        allUsers &&
                        allUsers?.find((user) => user.id == params.row.assignee)
                          ?.lastName
                      }`}
                      src={
                        params?.row?.assignee > 0
                          ? `api/image/user/${params.row.assignee}`
                          : ""
                      }
                    />
                    <Typography
                      style={{ paddingLeft: "10px", pointerEvents: "none" }}
                    >
                      <span
                        style={{
                          display: "inline-block",
                          lineBreak: "anywhere",
                        }}
                      >
                        {allUsers &&
                          allUsers?.find(
                            (user) => user.id == params.row.assignee
                          )?.firstName}
                        &nbsp;
                      </span>

                      <span
                        style={{
                          display: "inline-block",
                          lineBreak: "anywhere",
                        }}
                      >
                        {allUsers &&
                          allUsers?.find(
                            (user) => user.id == params.row.assignee
                          )?.lastName}
                      </span>
                    </Typography>
                  </div>
                </Tooltip>
              </>
            )}
          </div>
        )
      },
      renderEditCell: (params) => {
        let topParams = params
        return (
          <>
            <Autocomplete
              disablePortal
              id="assignee-search"
              fullWidth
              loading={loading}
              options={allUsers}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              sx={{ padding: "5px" }}
              popupIcon={<KeyboardArrowDownIcon />}
              value={assigneeObj}
              onChange={(e, newValue) =>
                handleAssigneeChange(e, newValue, topParams)
              }
              renderInput={(params) => (
                <TextField {...params} label="Assignee" />
              )}
              getOptionLabel={(option) =>
                option.firstName ? `${option.firstName} ${option.lastName}` : ""
              }
              renderOption={(params, option) => {
                // console.log('params in render',props,option)
                return (
                  <Stack
                    direction="row"
                    spacing={1}
                    {...params}
                    key={params["data-option-index"]}
                  >
                    <Avatar
                      sx={{
                        bgcolor: "#195FFB17",
                        color: "#195FFB",
                        fontFamily: "Archivo",
                        fontWeight: "600",
                        width: 28,
                        height: 28,
                        fontSize: 14,
                      }}
                      src={option?.id > 0 ? `api/image/user/${option.id}` : ""}
                    >
                      {`${params?.key[0].toUpperCase()}${params?.key[1].toUpperCase()}`}
                    </Avatar>
                    <span
                      style={{
                        fontFamily: "Archivo",
                        paddingLeft: "10px",
                        margin: "auto 0px",
                      }}
                    >
                      {params.key}
                    </span>
                  </Stack>
                )
              }}
            />
          </>
        )
      },
    },
  ]

  const [expanded, setExpanded] = useState(true)

  // Ticket Filter represent the interactions outside of the data grid

  const [ticketFilters, setTicketFilters] = useState({})
  // Returns all filters from local storage or builds new
  const getTicketFilters = () => {
    let _ticketFilters = localStorage.getItem("ticketFilters")
    if (_ticketFilters) {
      _ticketFilters = JSON.parse(_ticketFilters)
      // console.log("they exist", _ticketFilters)
      // Checks are null on page load
      setChecks({
        open: _ticketFilters.open,
        closed: _ticketFilters.closed,
        system: _ticketFilters.system,
      })
      setSelectedList(_ticketFilters.selectedList)
      setTicketFilters(_ticketFilters)
    } else {
      console.log("they dont exist")
      setChecks({ open: true, closed: false, system: false })
      setTicketFilters({
        open: true,
        closed: false,
        system: false,
        selectedList: "My Tickets",
      })
      setSelectedList("My Tickets")
    }
  }

  // Updates local storage with current settings
  const saveTicketFilters = () => {
    localStorage.setItem("ticketFilters", JSON.stringify(ticketFilters))
    getTicketFilters()
  }

  // Updates state if interacted with
  useEffect(() => {
    if (ticketFilters.open != undefined) {
      saveTicketFilters()
    }
  }, [checks.open, checks.closed, checks.system, selectedList])

  // Filter Models represent the interactions inside of the data grid

  const [filterModel, setFilterModel] = useState({
    items: [],
    linkOperator: "and",
    quickFilterLogicOperator: "and",
    quickFilterValues: [],
  })

  // Returns all filters from local storage or builds new
  const [storedFilters, setStoredFilters] = useState({})
  const getFilterModels = () => {
    let _filterModels = localStorage.getItem("ticketFilterModel")
    if (_filterModels) {
      _filterModels = JSON.parse(_filterModels)
      setFilterModel(_filterModels)
      setStoredFilters(_filterModels)
    } else {
      console.log("they dont exist")
    }
  }

  const saveTicketFilterModel = () => {
    if (
      filterModel !=
        {
          items: [],
          linkOperator: "and",
          quickFilterLogicOperator: "and",
          quickFilterValues: [],
        } &&
      filterModel?.items?.length > 0
    ) {
      localStorage.setItem("ticketFilterModel", JSON.stringify(filterModel))
      getFilterModels()
    }
    setMessage({
      ...message,
      messageDisplay: true,
      autoHideDuration: 1500,
      backgroundColor: "primary.main",
      severity: "success",
      message: "Successfully Saved Filter",
    })
  }

  const clearTicketFilterModel = () => {
    console.log("Clear me!")
    setFilterModel({
      items: [],
      linkOperator: "and",
      quickFilterLogicOperator: "and",
      quickFilterValues: [],
    })
    localStorage.removeItem("ticketFilterModel")
    setMessage({
      ...message,
      messageDisplay: true,
      autoHideDuration: 1500,
      backgroundColor: "primary.main",
      severity: "success",
      message: "Successfully Cleared Filter",
    })
    setStoredFilters({})
  }

  // Runs on init

  useEffect(() => {
    getTicketFilters()
    getFilterModels()
  }, [])

  const renderStoredFilter = () => {
    let field, operator, valueSentence
    // If the object exists, and if it has an item within it
    if (
      Object.keys(storedFilters).length > 0 &&
      storedFilters?.items?.length > 0
    ) {
      field = storedFilters?.items[0]?.columnField
      operator = storedFilters?.items[0]?.operatorValue
      // If the value field exists
      if (
        storedFilters?.items[0]?.value != undefined &&
        storedFilters?.items[0]?.value.length > 0
      ) {
        if (Array.isArray(storedFilters?.items[0]?.value)) {
          // If the value is an array
          valueSentence = storedFilters?.items[0].value.join(" ")
        } else if (storedFilters?.items[0]?.value == null || undefined) {
          // Value doesn't exist
          valueSentence = ""
        } else {
          // Value is a string
          valueSentence = storedFilters?.items[0]?.value
        }
      } else {
        // The value doesn't exist
        valueSentence = ""
      }
    }
    return `${field} ${operator} ${valueSentence}`
  }

  //SNACKBAR LOGIC
  const [message, setMessage] = useState({
    messageDisplay: false,
    autoHideDuration: "",
    severity: "",
    backgroundColor: "",
    message: "",
  })

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setMessage({
        ...message,
        messageDisplay: false,
        autoHideDuration: "",
        severity: "",
        backgroundColor: "",
        message: "",
      })
    }, 5000)

    return () => {
      clearTimeout(timeId)
    }
  }, [message.messageDisplay])

  const handleMessageClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setMessage({
      ...message,
      messageDisplay: false,
      autoHideDuration: "",
      severity: "",
      backgroundColor: "",
      message: "",
    })
  }

  return (
    <Box
      sx={{
        bgcolor: "background.background",
        color: "text.primary",
      }}
      style={{ padding: "20px", height: "calc(100vh - 56px)" }}
    >
      <style>
        {`
               .popperOverride li, .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
               font-family:Poppins;
               font-size:.92rem;
               // color:red;
               }

               .hide-right-sep .MuiDataGrid-columnSeparator, .hide-left-sep .MuiDataGrid-columnSeparator {
                  visibility:hidden
               }
               .center-grid-header .MuiDataGrid-columnHeaderTitleContainer{
                  // color:red;
                  display:flex;
                  justify-content:center
               }
               .css-j204z7-MuiFormControlLabel-root {
                  margin-left:50px;
                  margin-right:20px;
               }
            `}
      </style>
      <div
        className="flex-row"
        style={{ fontFamily: "Archivo", flexWrap: "wrap", backgroundColor: "" }}
      >
        {/* MAIN AREA */}
        <div
          className="col-12"
          style={{ backgroundColor: "", flexWrap: "wrap" }}
        >
          <Box
            className="col"
            sx={{
              bgcolor: "background.default",
              color: "text.primary",
              border: "solid 1px",
              borderBottom: "0",
              borderColor: "background.border",
            }}
            style={{
              display: "flex",
              padding: "23px 35px 0px",
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
            }}
          >
            <span
              style={{
                fontSize: "32px",
                fontWeight: 500,
                fontFamily: "Poppins",
              }}
            >
              Tickets
            </span>
            {/* <div className='col' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '50px' }}>
                     <Button variant='contained'
                        sx={{ height: 40, minWidth: 200, borderRadius: 100, width: '152px', fontWeight: '500!important' }}
                        onClick={() => setTicketDrawerOpen(true)}
                     // startIcon={<AddIcon/>}
                     >
                        Open New Ticket
                     </Button>
                  </div> */}
          </Box>

          {/* NEW TICKET DRAWER */}
          <CreateTicketDrawer
            refreshTickets={refreshTickets}
            ticketDrawerOpen={ticketDrawerOpen}
            setTicketDrawerOpen={setTicketDrawerOpen}
            member={props.member}
            singleEnrollment={props.singleEnrollment}
            user={thisUser}
            origins={origins}
            types={types}
            priorities={priorities}
            showMemberInput
          />

          <Box
            sx={{
              bgcolor: "background.default",
              color: "text.primary",
              borderLeft: "solid 1px",
              borderRight: "solid 1px",
              borderColor: "background.border",
            }}
            className="flex-row"
            style={{
              fontWeight: 500,
              padding: "0px 10px",
              alignItems: "center",
            }}
          >
            <FormControl
              sx={{
                bgColor: "background.paper",
                borderRadius: "8px",
                boxShadow: 2,
                m: 1,
                minWidth: 120,
                padding: "0px 10px",
              }}
            >
              <Select
                fullWidth
                renderValue={(value) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        bgColor: "background.paper",
                      }}
                    >
                      <SvgIcon color="primary">
                        <FilterList />
                      </SvgIcon>
                      {value}
                    </Box>
                  )
                }}
                variant="standard"
                disableUnderline
                sx={{
                  borderRadius: "8px",
                  bgColor: "background.paper",
                  padding: "10px",
                }}
                value={selectedList}
                onChange={handleChangeSelectedList}
                inputProps={{ color: "red" }}
              >
                <MenuItem value={"My Tickets"}>My Tickets</MenuItem>
                <MenuItem value={"All Tickets"}>All Tickets</MenuItem>
              </Select>
            </FormControl>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checks.open}
                    value="open"
                    onClick={handleCheckBox}
                  />
                }
                label="Open"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checks.closed}
                    value="closed"
                    onClick={handleCheckBox}
                  />
                }
                label="Closed"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checks.system}
                    value="system"
                    onClick={handleCheckBox}
                  />
                }
                label="System Assigned"
              />
            </FormGroup>
            {Object.keys(storedFilters).length > 0 &&
              storedFilters?.items?.length > 0 && (
                <Box sx={{ position: "absolute", right: "40px" }}>
                  <Chip label={renderStoredFilter()}></Chip>
                  <Button onClick={() => clearTicketFilterModel()}>
                    Clear Saved Filter
                  </Button>
                </Box>
              )}

            <>
              <TextField
                id="member-search"
                type="search"
                variant="outlined"
                // value={searchState.terms} onChange={handleTermsInput}
                // onKeyPress={(e) => e.key === 'Enter' && searchAsync()}

                // label=" " InputLabelProps={{sx:{color:"#CCCCCC",fontFamily:'Poppins',fontSize:14}}}
                placeholder="Search tickets"
                disabled // until working, or just hide
                size="small"
                fullWidth
                sx={{
                  visibility: "hidden",
                  display: "block",
                  marginLeft: "auto",
                  width: "30%",
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  "& input::placeholder": {
                    fontFamily: "Poppins",
                    fontSize: "14px",
                  },
                  "& .Mui-focused > fieldset": {
                    border: "2px solid #195FFB!important",
                  },
                }}
                InputProps={{
                  // endAdornment:searchAdornment,
                  startAdornment: searchAdornment,
                  sx: { paddingLeft: "0px" },
                }}
              />
            </>
          </Box>
        </div>

        <div
          className="col-12"
          id="all-tickets-grid"
          style={{ height: "calc(100vh - 230px)" }}
        >
          <OurDataGrid
            manualHeight
            columnVisibilityModel={gridCols}
            setGridCols={setGridCols}
            loadingState={loadingState}
            rows={rows}
            columns={columns}
            handleRowClick={handleRowClick}
            manualStyle={{
              borderRadius: "15px",
              borderTopLeftRadius: "0px",
              borderTopRightRadius: "0px",
            }}
            noRowsComp={NoTicketRows}
            noResultsComp={NoTicketResults}
            initialSort="dueDate"
            initialSortDirection="asc"
            cellEditCommit={sendUpdatedTicket}
            setFilterModel={setFilterModel}
            filterModel={filterModel}
            saveFilterModel={saveTicketFilterModel}
            clearFilterModel={clearTicketFilterModel}
            showSaveFilterModel={true}
          />
        </div>
      </div>
      {/* Messages */}
      <Snackbar
        open={message.messageDisplay}
        autoHideDuration={message.autoHideDuration}
        onClose={handleMessageClose}
        sx={{ opacity: 0.8 }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          // onClose={handleClose}
          severity={message.severity}
          sx={{ width: "100%", backgroundColor: message.backgroundColor }}
        >
          {message.message}
        </MuiAlert>
      </Snackbar>
    </Box>
  )
}
