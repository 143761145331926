import React, { useState, useEffect } from 'react';

//MuiIcons
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonIcon from '@mui/icons-material/RadioButtonUnchecked';

//MuiComponents
import Collapse from '@mui/material/Collapse';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Divider from '@mui/material/Divider';
import { Box, Tooltip, Typography } from '@mui/material';

//Our icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

//Our Components
import RecommendedBenefitDisplay from '../../RecommendedBenefitDisplay';
import ExpandAndCollapseIcon from '../../enrollment-custom-components/ExpandAndCollapseIcon';

//Our Enrollment Plan Styles
import enrollmentPlanStyles from '../enrollmentPlanStyles';
import externalEnrollmentSharedStyles from '../../../externalEnrollmentSharedStyles';

export default function EnrollmentLtd(props) {
  let UsdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  let UsdFormatterDec = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  let results = props.results;
  let ltdPlan = results?.ltdPlan;
  let ltdHelpText = ltdPlan?.helpText;

  const [expanded, setExpanded] = useState(true);
  const handleExpandSection = () => {
    setExpanded(!expanded);
  };

  const [currRec, setCurrRec] = useState(0);
  useEffect(() => {
    //if recs exist, check rec
    if (props?.results?.ltdPlan?.options?.length > 0) {
      //check each option against rec w/ the selectionRec function - function returns true if option = rec
      let _recValue = results?.ltdPlan?.options?.find((option, index) => {
        let _rec = props?.selectionRec(props?.results?.ltdPlan?.options, index);
        if (_rec) return _rec;
      });

      //if rec exists, set currRec to rec value
      if (_recValue) setCurrRec(_recValue);
      console.log('rec value returned', _recValue);
    }
  }, [props?.recommendations, props?.results?.ltdPlan?.options]);

  return (
    <>
      {/* <style>
        {`
        // ul {
        //     margin-left: 0
        // }
        .plan-explanation-innerHTML ul {
          padding-left: 1.3rem;
        }
        `}
      </style> */}

      <Box sx={enrollmentPlanStyles.planContainer(expanded)}>
        <Typography
          sx={enrollmentPlanStyles.planTitle}
          onClick={handleExpandSection}
        >
          Long-Term Disability
          {/* bigger clickable area */}
        </Typography>

        <Box
          sx={enrollmentPlanStyles.expandAndCollapseButton}
          onClick={handleExpandSection}
        >
          <ExpandAndCollapseIcon isExpanded={expanded} />
        </Box>
      </Box>

      <Collapse
        sx={enrollmentPlanStyles.collapseSection}
        in={expanded}
        unmountOnExit
      >
        <Box sx={enrollmentPlanStyles.planExplanationInnerHtmlContainer}>
          <span
            className="plan-explanation-innerHTML"
            dangerouslySetInnerHTML={{ __html: ltdHelpText }}
          ></span>
        </Box>

        <RecommendedBenefitDisplay currRec={currRec} />

        <RadioGroup
          onChange={props.selectionHandler}
          value={props.selections.ltd || currRec?.memberOptionId}
        >
          {results &&
            ltdPlan.options
              .sort((a, b) => a.displayOrder - b.displayOrder)
              .map((option, index) => {
                let isSelected =
                  props.selections.ltd == option.memberOptionId ||
                  (!props.selections.ltd &&
                    currRec?.memberOptionId == option.memberOptionId);

                if (!option.isDecline) {
                  return (
                    <React.Fragment key={`${JSON.stringify(option)}`}>
                      <Box sx={enrollmentPlanStyles.optionRow}>
                        {/* radio button, benefit amount, type */}
                        <Box
                          sx={enrollmentPlanStyles.optionRadioAndTextContainer}
                        >
                          <Radio
                            sx={enrollmentPlanStyles.planSelectRadioButton}
                            value={option.memberOptionId}
                            id={`${option.memberOptionId}`}
                            name="ltd"
                            icon={
                              <RadioButtonIcon
                                sx={enrollmentPlanStyles.radioButtonIcon(false)}
                              />
                            }
                            checkedIcon={
                              <RadioButtonCheckedIcon
                                sx={enrollmentPlanStyles.radioButtonIcon(false)}
                              />
                            }
                            onClick={() => {
                              props.handleEnrollClick(option.memberOptionId);
                              // props.setExpanded({...props.expanded,  ladd: true })
                            }}
                            inputProps={{
                              'data-amount': option.memberPremiumAmount,
                              'data-uhtest': 'LTD_' + option.name,
                            }}
                          />

                          <Box
                            sx={enrollmentPlanStyles.singleOptionTextContainer}
                          >
                            <Typography
                              sx={enrollmentPlanStyles.singleOptionText(
                                isSelected
                              )}
                            >
                              {option?.name && (
                                <>
                                  {option.name}{' '}
                                  {option.name.includes('%') &&
                                    option?.benefitAmount &&
                                    `(Max ${UsdFormatter.format(
                                      option.benefitAmount
                                    )})`}
                                </>
                              )}
                            </Typography>

                            {option?.memberOptionId ==
                              currRec?.memberOptionId && (
                              <Typography
                                sx={enrollmentPlanStyles.recommendedText}
                              >{` (recommended)`}</Typography>
                            )}
                          </Box>
                        </Box>

                        {/* premium amount */}
                        <Box
                          sx={enrollmentPlanStyles.singleOptionText(isSelected)}
                        >
                          {isSelected && '+'}
                          {!props.dummyDisplay &&
                            UsdFormatterDec.format(option.memberPremiumAmount)}
                          {props.dummyDisplay && '$0.00'}
                        </Box>
                      </Box>

                      {/* explanation of benefits */}
                      {option.explanationOfBenefits && (
                        <Box
                          sx={enrollmentPlanStyles.optionExplanationOfBenefits}
                        >
                          {option.explanationOfBenefits}
                        </Box>
                      )}

                      {/* benefit offsets */}
                      {option.benefitOffsets && (
                        <Box
                          sx={enrollmentPlanStyles.optionExplanationOfBenefits}
                        >
                          {option.benefitOffsets}
                        </Box>
                      )}
                    </React.Fragment>
                  );
                } else {
                  return (
                    //decline option
                    <Box
                      key={`${option.memberPremiumAmount}`}
                      sx={enrollmentPlanStyles.declineOptionRow}
                    >
                      <Radio
                        sx={enrollmentPlanStyles.planSelectRadioButton}
                        value={option.memberOptionId}
                        name="ltd"
                        icon={
                          <RadioButtonIcon
                            sx={enrollmentPlanStyles.radioButtonIcon(false)}
                          />
                        }
                        checkedIcon={
                          <RadioButtonCheckedIcon
                            sx={enrollmentPlanStyles.radioButtonIcon(false)}
                          />
                        }
                        onClick={() => {
                          props.handleEnrollClick(option.memberOptionId);
                        }}
                        inputProps={{
                          'data-amount': option.memberPremiumAmount,
                          'data-uhtest': 'LTD_Decline',
                        }}
                      />
                      <Box
                        sx={enrollmentPlanStyles.declineOptionText(isSelected)}
                      >
                        {props?.results?.name?.includes('SMART-TD') &&
                        new Date() < new Date('12/31/2023')
                          ? 'Keep Existing Coverage'
                          : 'Not Now'}
                      </Box>
                      {props?.results?.name?.includes('SMART-TD') &&
                        new Date() > new Date('12/31/2023') && (
                          <Tooltip
                            title={
                              ltdPlan?.infoTipText ||
                              'If you decline coverage, you may not be able to enroll in the future.'
                            }
                            placement="bottom-end"
                            arrow
                            enterTouchDelay={0}
                          >
                            <Box sx={enrollmentPlanStyles.notNowToolTip}>
                              <InfoOutlinedIcon
                                sx={externalEnrollmentSharedStyles.grayInfoTip}
                              />
                            </Box>
                          </Tooltip>
                        )}
                    </Box>
                  );
                }
              })}
        </RadioGroup>
      </Collapse>
      {!expanded && (
        <Box sx={enrollmentPlanStyles.collapsedPlanSection}>
          {props.selections.ltd && props.selections.ltdVal > 0 && (
            <>
              <Box>
                <RadioButtonCheckedIcon
                  sx={enrollmentPlanStyles.radioButtionIconCollapse}
                />
                {
                  ltdPlan.options.find(
                    (option) => option.memberOptionId == props.selections.ltd
                  )?.name
                }
              </Box>

              <Box sx={enrollmentPlanStyles.electedOptionCollapsePlusSymbol}>
                + {UsdFormatterDec.format(props.selections.ltdVal)}
              </Box>
            </>
          )}
          {props.selections.ltd && props.selections.ltdVal == 0 && (
            <>
              <Typography>
                <em>
                  {props.results.name.includes('SMART-TD') &&
                  new Date() < new Date('12/31/2023')
                    ? 'Keep Existing Coverage'
                    : 'not elected'}
                </em>
              </Typography>
              <Typography sx={enrollmentPlanStyles.notElectedZeroAmount}>
                $0.00
              </Typography>
            </>
          )}
          {!props.selections.ltd && 'No plan selected'}
        </Box>
      )}
      <Divider sx={enrollmentPlanStyles.endSectionDivider} />
    </>
  );
}
