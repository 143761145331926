import React, { cloneElement, createContext, useEffect, useState } from 'react';
import {
  Switch,
  useHistory,
  useLocation,
  Route,
  useParams,
} from 'react-router-dom';
import { format } from 'date-fns';

//Custom Css
import '../../EnrollmentStyles/planHelpTextStyles.css';

//Assets
import FullColorBlackText from '../../../assets/images/FullColorBlackText.svg';

//Muicons

//MuiComponents
import Box from '@mui/material/Box';
//Services
import memberService from '../../../services/member-service';
import enrollmentService from '../../../services/enrollment-service';
import planService from '../../../services/plan-service';
import calculatorService from '../../../services/calculator-service';
import optionService from '../../../services/optionService';

//Our Components
import ExternalEnrollmentHeader from './ExternalEnrollmentHeader';
import ExternalEnrollmentCalculator from './ExternalEnrollmentCalculator';
import EnrollmentPlans from './enrollment-plans/EnrollmentPlans';
// import InfinityLoader from '../../old-loading-animation/UHLoadingAnimation';
import InfinityLoader from '../../UH-loading-animation/InfinityLoader';
import EnrollmentBeneficiaries from './enrollment-beneficiaries/EnrollmentBeneficiaries';
import EnrollmentPayment from './enrollment-plans/payment-components/payScheduleAndBank-components/EnrollmentPayment';
import EnrollmentPersonalInfo from './enrollment-personal-info/EnrollmentPersonalInfo';
import StickyProgressTotal from './StickyProgressTotal';
import EnrollmentComplete from './enrollment-complete/EnrollmentComplete';
import EnrollmentProgressSaved from './progress-saved/EnrollmentProgressSaved';

//utils
import {
  checkMobileDevice,
  userTimeZone,
  formatUTCDateToObjWithTimeZone,
} from '../../utils';
import PoweredBy from './PoweredBy';

//shared styles
import externalEnrollmentSharedStyles from '../externalEnrollmentSharedStyles';

export let enrollmentContext = createContext();

export default function SingleEnrollmentWrapper(props) {
  const isMobileDevice = checkMobileDevice();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  // let route = location.pathname;
  let basicLocation = window.location.href;
  let subdomain = basicLocation
    .split('.')[0]
    .replace('https://', '')
    .replace('http://', '');

  const getCookie = (cookieName) => {
    let name = `${cookieName}=`;
    let cookieArray = document.cookie.split(';');
    let value = '';
    cookieArray.forEach((cookie) => {
      if (cookie.includes(name)) value = cookie.replace(name, '').trim();
    });
    return value;
  };

  useEffect(() => {
    if (basicLocation) {
      let cookieSubdomain = getCookie('subdomain')
        .split('.')[0]
        .replace('https://', '')
        .replace('http://', '');
      if (cookieSubdomain !== subdomain) {
        window.location.href = basicLocation.replace(
          subdomain,
          cookieSubdomain
        );
      }
    }
  }, [basicLocation]);

  //attempting to replace this code w/ checkMobileDevice function from utils folder
  // let theme = useTheme();
  // function iOS() {
  //   return [
  //   'iPad Simulator',
  //   'iPad',

  // ].includes(navigator.platform)
  //   // iPad on iOS 13 detection
  //   || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  // }

  // const isIOS = iOS();
  // // console.log('is iOS', isIOS)

  // //check if iOS, if so, make breakpoint lg to work for iPad
  // let breakpointSize;

  // if (isIOS) {
  //     breakpointSize = 'xl'
  // } else {
  //   breakpointSize = 'md'
  // }

  // let medBreakpointHit = useMediaQuery(theme.breakpoints.down(breakpointSize))

  const [loading, setLoading] = useState(false);

  const bodyHeight = loading ? '100vh' : '';

  const [fullMemberObj, setFullMemberObj] = useState({});
  const [userInfo, setUserInfo] = useState({
    GroupPlanId: null,
    Salary: '',
    dob: '',
    OrganizationId: null,
    spouseDOB: null,
  });

  const [subgroupOrgs, setSubgroupOrgs] = useState([]);

  const getFullMemberObj = async () => {
    let me;
    try {
      me = await memberService.getMe();
    } catch (error) {
      console.log('error getting me from member service', error);
    }
    setFullMemberObj(me);
    getFullSocial();
    console.log('ME', me);
    return me;
  };

  const getMe = async () => {
    setLoading(true);
    let me = await getFullMemberObj();

    let subgroupsResults;
    try {
      subgroupsResults = await calculatorService.getSubGroups();
    } catch (error) {
      console.log('error getting subgroups', error);
    }
    //currently not using subgroupOrgs in enrollment personal info to get orgs
    setSubgroupOrgs(subgroupsResults.organizations);
    let groupPlanId = null;
    let organizationId = null;
    //If there's only one choice, then that's our id
    // if (subgroupsResults.groupPlans.length == 1)
    //   groupPlanId = subgroupsResults.groupPlans[0].id;
    groupPlanId = params.groupPlanId;

    //format dob from db debending on device
    let formattedDob = null;
    if (me?.dob) {
      //format dob w/ timezone
      const dobWithTimezone = formatUTCDateToObjWithTimeZone(me.dob);
      //format further for device
      // if(isMobileDevice){
      //   formattedDob = dobWithTimezone //MUI datepicker format
      // }else{
      //   formattedDob = format(new Date(dobWithTimezone), "yyyy-MM-dd"); //MUI textfield format
      // }
      //testing out formatting only once for DatePicker that is used for both large and small devices
      formattedDob = dobWithTimezone;
    }
    if (me?.returnToWork) {
      me.returnToWork = formatUTCDateToObjWithTimeZone(me.returnToWork);
    }

    let _userInfo = {
      dob: formattedDob,
      Salary: me?.annualSalary,
      GroupPlanId: groupPlanId,
      OrganizationId: me?.organizationId,
      spouseDOB: spouse?.dateOfBirth,
    };

    setUserInfo(_userInfo);

    //logic for getting member rates - do not need formatted dob here
    let _userInfoCalc = {
      dob: me?.dob,
      Salary: me?.annualSalary,
      GroupPlanId: groupPlanId,
      OrganizationId: me?.organizationId,
      spouseDOB: spouse?.dateOfBirth,
    };

    let _results;
    try {
      _results = await calculatorService.updateMemberCalculateRates(
        _userInfoCalc
      );
    } catch (error) {
      console.log('error calculating', error);
    }
    setResults(_results);
    setLoading(false);
  };

  useEffect(() => {
    getMe();
    window.scrollTo(0, 0);
  }, []);

  //CALCULATION LOGIC

  //Plan info displayed to user
  const [results, setResults] = useState(null);
  //Set selections when we have results
  const [openEnrollment, setOpenEnrollment] = useState(true);
  const [isEnrolled, setIsEnrolled] = useState(false);

  //state to handle member + spouse, or member + dependents/family error handling info
  const [accidentSelection, setAccidentSelection] = useState(null);

  //state to handle member + spouse, or member + dependents/family error handling info
  const [cancerSelection, setCancerSelection] = useState(null);

  //state to handle member + spouse, or member + dependents/family error handling info
  const [addSelection, setAddSelection] = useState(null);

  //state to handle member + spouse, or member + dependents/family error handling info
  const [hospitalSelection, setHospitalSelection] = useState(null);

  useEffect(() => {
    let builtSelections = {
      std: undefined, //optionId
      stdVal: 0, //PremiumAmount
      ltd: undefined, //optionId
      ltdVal: 0, //PremiumAmount
      ladd: [0, 0, 0], // Coverage Amounts
      laddVal: [0, 0, 0], //Premium amounts
      laddId: undefined, //planId
      accident: undefined,
      accidentVal: 0,
      cancer: undefined,
      cancerVal: 0,
      add: undefined,
      addVal: 0,
      hospital: undefined,
      hospitalVal: 0,
      membership: undefined,
      membershipVal: 0,
      criticalIllness: [0, 0, 0],
      criticalIllnessVal: [0, 0, 0],
      criticalIllnessId: undefined,
    };

    let builtLocalBennyRequiredPlans = {
      std: false,
      ltd: false,
      ladd: false,
      accident: false,
      cancer: false,
      add: false,
      hospital: false,
      membership: false,
      criticalIllness: false,
    };

    //std
    if (results?.stdPlan && results.stdPlan.selectedOptionId > 0) {
      builtSelections.std = results.stdPlan.selectedOptionId;
      builtSelections.stdVal = results.stdPlan.options.find(
        (option) => option.memberOptionId == builtSelections.std
      )?.memberPremiumAmount;

      //check for incremental plan and decline only option - if so, preselect declined & 0 amount, even if previous plan selected was not declined, decline is only option now due to salary change
      if (
        results?.stdPlan?.isIncremental &&
        results?.stdPlan?.options?.length === 1 &&
        results?.stdPlan?.options[0]?.isDecline
      ) {
        builtSelections.std = results?.stdPlan?.options[0]?.memberOptionId;
        builtSelections.stdVal = 0;
      }

      //if stdVal = 0, decline is already stored in db
      if (!builtSelections.stdVal && builtSelections.stdVal !== 0) {
        builtSelections.std = undefined;
        builtSelections.stdVal = 0;
      }
      if (builtSelections.stdVal > 0) {
        builtLocalBennyRequiredPlans.std = results.stdPlan.isBeneficiaryEnabled;
      }

      //check for incremental plan and decline only option w/ non results saved in db yet - if so, preselect at declined & 0 amount
    } else if (
      results?.stdPlan?.isIncremental &&
      results?.stdPlan?.options?.length === 1 &&
      results?.stdPlan?.options[0]?.isDecline
    ) {
      builtSelections.std = results?.stdPlan?.options[0]?.memberOptionId;
      builtSelections.stdVal = 0;
    }

    //ltd
    if (results?.ltdPlan && results.ltdPlan.selectedOptionId > 0) {
      builtSelections.ltd = results.ltdPlan.selectedOptionId;
      builtSelections.ltdVal = results.ltdPlan.options.find(
        (option) => option.memberOptionId == builtSelections.ltd
      )?.memberPremiumAmount;

      //check for incremental plan and decline only option - if so, preselect declined & 0 amount, even if previous plan selected was not declined, decline is only option now due to salary change
      if (
        results?.ltdPlan?.isIncremental &&
        results?.ltdPlan?.options?.length === 1 &&
        results?.ltdPlan?.options[0]?.isDecline
      ) {
        builtSelections.ltd = results?.ltdPlan?.options[0]?.memberOptionId;
        builtSelections.ltdVal = 0;
      }

      //if ltdVal = 0, decline is already stored in db
      if (!builtSelections.ltdVal && builtSelections.ltdVal !== 0) {
        builtSelections.ltd = undefined;
        builtSelections.ltdVal = 0;
      }
      if (builtSelections.ltdVal > 0) {
        builtLocalBennyRequiredPlans.ltd = results.ltdPlan.isBeneficiaryEnabled;
      }
    } else if (
      results?.ltdPlan?.isIncremental &&
      results?.ltdPlan?.options?.length === 1 &&
      results?.ltdPlan?.options[0]?.isDecline
    ) {
      builtSelections.ltd = results?.ltdPlan?.options[0]?.memberOptionId;
      builtSelections.ltdVal = 0;
    }

    //ladd
    if (results?.lifeADDPlan) {
      builtSelections.laddId = results.lifeADDPlan.planId;
      if (results.lifeADDPlan.memberOptionsAreEnabled) {
        let selectedOption = results.lifeADDPlan.memberOptions.find(
          (option) => option.isSelected
        );
        if (selectedOption) {
          builtSelections.ladd[0] = selectedOption.coverageAmount;
          builtSelections.laddVal[0] = selectedOption.premiumAmount;
        }
      }

      if (results.lifeADDPlan.memberAndSpouseOptionsAreEnabled) {
        let selectedOption = results.lifeADDPlan.spouseOptions.find(
          (option) => option.isSelected
        );
        if (selectedOption) {
          builtSelections.ladd[1] = selectedOption.coverageAmount;
          builtSelections.laddVal[1] = selectedOption.premiumAmount;
        }
      }

      if (results.lifeADDPlan.memberAndDependentsAreEnabled) {
        let selectedOption = results.lifeADDPlan.dependentOptions.find(
          (option) => option.isSelected
        );
        if (selectedOption) {
          builtSelections.ladd[2] = selectedOption.coverageAmount;
          builtSelections.laddVal[2] = selectedOption.premiumAmount;
        }
      }
      if (builtSelections.laddVal[0] > 0)
        builtLocalBennyRequiredPlans.ladd =
          results.lifeADDPlan.isBeneficiaryEnabled;
    }

    //membership
    if (
      results?.membershipPlan &&
      results.membershipPlan.selectedOptionId > 0
    ) {
      builtSelections.membership = results.membershipPlan.selectedOptionId;
      builtSelections.membershipVal = results.membershipPlan.options.find(
        (option) => option.memberOptionId == builtSelections.membership
      )?.memberPremiumAmount;

      //check for incremental plan and decline only option - if so, preselect declined & 0 amount, even if previous plan selected was not declined, decline is only option now due to salary change
      if (
        results?.membershipPlan?.isIncremental &&
        results?.membershipPlan?.options?.length === 1 &&
        results?.membershipPlan?.options[0]?.isDecline
      ) {
        builtSelections.membership =
          results?.membershipPlan?.options[0]?.memberOptionId;
        builtSelections.membershipVal = 0;
      }

      //if membershipVal = 0, decline is already stored in db
      if (
        !builtSelections.membershipVal &&
        builtSelections.membershipVal !== 0
      ) {
        builtSelections.membership = undefined;
        builtSelections.membershipVal = 0;
      }
      if (builtSelections.membershipVal > 0) {
        builtLocalBennyRequiredPlans.membership =
          results.membershipPlan.isBeneficiaryEnabled;
      }
    }

    //criticalIllness
    if (results?.criticalIllnessPlan) {
      builtSelections.criticalIllnessId = results.criticalIllnessPlan.planId;
      if (results.criticalIllnessPlan.memberOptionsAreEnabled) {
        let selectedOption = results.criticalIllnessPlan.memberOptions.find(
          (option) => option.isSelected
        );
        if (selectedOption) {
          builtSelections.criticalIllness[0] = selectedOption.coverageAmount;
          builtSelections.criticalIllnessVal[0] = selectedOption.premiumAmount;
        }
      }

      if (results.criticalIllnessPlan.memberAndSpouseOptionsAreEnabled) {
        let selectedOption = results.criticalIllnessPlan.spouseOptions.find(
          (option) => option.isSelected
        );
        if (selectedOption) {
          builtSelections.criticalIllness[1] = selectedOption.coverageAmount;
          builtSelections.criticalIllnessVal[1] = selectedOption.premiumAmount;
        }
      }

      if (results.criticalIllnessPlan.memberAndDependentsAreEnabled) {
        let selectedOption = results.criticalIllnessPlan.dependentOptions.find(
          (option) => option.isSelected
        );
        if (selectedOption) {
          builtSelections.criticalIllness[2] = selectedOption.coverageAmount;
          builtSelections.criticalIllnessVal[2] = selectedOption.premiumAmount;
        }
      }
      if (builtSelections.criticalIllnessVal[0] > 0)
        builtLocalBennyRequiredPlans.criticalIllness =
          results.criticalIllnessPlan.isBeneficiaryEnabled;
    }

    //accident
    if (results?.accidentPlan && results.accidentPlan.selectedOptionId > 0) {
      builtSelections.accident = results.accidentPlan.selectedOptionId;
      if (results.accidentPlan.memberIsSelected) {
        let selectedOption = results.accidentPlan.options.find(
          (option) => option.memberOptionId == builtSelections.accident
        );
        builtSelections.accidentVal = selectedOption.memberPremiumAmount;
      } else if (results.accidentPlan.memberAndSpouseIsSelected) {
        let selectedOption = results.accidentPlan.options.find(
          (option) => option.memberAndSpouseOptionId == builtSelections.accident
        );
        builtSelections.accidentVal =
          selectedOption.memberAndSpousePremiumAmount;
      } else if (results.accidentPlan.memberAndDependentsIsSelected) {
        let selectedOption = results.accidentPlan.options.find(
          (option) =>
            option.memberAndDependentsOptionId == builtSelections.accident
        );
        builtSelections.accidentVal =
          selectedOption.memberAndDependentsPremiumAmount;
      } else if (results.accidentPlan.familyIsSelected) {
        let selectedOption = results.accidentPlan.options.find(
          (option) => option.familyOptionId == builtSelections.accident
        );
        builtSelections.accidentVal = selectedOption.familyPremiumAmount;
      }
      if (builtSelections.accidentVal > 0)
        builtLocalBennyRequiredPlans.accident =
          results.accidentPlan.isBeneficiaryEnabled;
    }

    //Accident plan initial plan selected - logic for error handlling only
    let _accidentPlanSelection = results?.accidentPlan?.options?.map(
      (option) => {
        if (selections.accident === option.memberOptionId) return 'member';
        else if (selections.accident === option.memberAndSpouseOptionId)
          return 'member and spouse';
        else if (selections.accident === option.memberAndDependentsOptionId)
          return 'member and dependents';
        else if (selections.accident === option.familyOptionId) return 'family';
        else return null;
      }
    );

    setAccidentSelection(_accidentPlanSelection);

    //cancer
    if (results?.cancerPlan && results.cancerPlan.selectedOptionId > 0) {
      builtSelections.cancer = results.cancerPlan.selectedOptionId;
      if (results.cancerPlan.memberIsSelected) {
        let selectedOption = results.cancerPlan.options.find(
          (option) => option.memberOptionId == builtSelections.cancer
        );
        builtSelections.cancerVal = selectedOption.memberPremiumAmount;
      } else if (results.cancerPlan.memberAndSpouseIsSelected) {
        let selectedOption = results.cancerPlan.options.find(
          (option) => option.memberAndSpouseOptionId == builtSelections.cancer
        );
        builtSelections.cancerVal = selectedOption.memberAndSpousePremiumAmount;
      } else if (results.cancerPlan.memberAndDependentsIsSelected) {
        let selectedOption = results.cancerPlan.options.find(
          (option) =>
            option.memberAndDependentsOptionId == builtSelections.cancer
        );
        builtSelections.cancerVal =
          selectedOption.memberAndDependentsPremiumAmount;
      } else if (results.cancerPlan.familyIsSelected) {
        let selectedOption = results.cancerPlan.options.find(
          (option) => option.familyOptionId == builtSelections.cancer
        );
        builtSelections.cancerVal = selectedOption.familyPremiumAmount;
      }
    }
    if (builtSelections.cancerVal > 0)
      builtLocalBennyRequiredPlans.cancer =
        results.cancerPlan.isBeneficiaryEnabled;

    let _cancerPlanSelection = results?.cancerPlan?.options.map((option) => {
      if (selections.cancer === option.memberOptionId) return 'member';
      else if (selections.cancer === option.memberAndSpouseOptionId)
        return 'member and spouse';
      else if (selections.cancer === option.memberAndDependentsOptionId)
        return 'member and dependents';
      else if (selections.cancer === option.familyOptionId) {
        let onlyFamilyAndMemberAvailable =
          !option.memberAndDependentsAreEnabled &&
          !option.memberAndSpouseOptionsAreEnabled &&
          option.familyOptionsAreEnabled &&
          option.memberOptionsAreEnabled;
        if (onlyFamilyAndMemberAvailable) return 'familyOptional';
        else return 'family';
      } else return null;
    });

    setCancerSelection(_cancerPlanSelection);

    //add
    if (results?.addPlan && results.addPlan.selectedOptionId > 0) {
      builtSelections.add = results.addPlan.selectedOptionId;
      if (results.addPlan.memberIsSelected) {
        let selectedOption = results.addPlan.options.find(
          (option) => option.memberOptionId == builtSelections.add
        );
        builtSelections.addVal = selectedOption.memberPremiumAmount;
      } else if (results.addPlan.memberAndSpouseIsSelected) {
        let selectedOption = results.addPlan.options.find(
          (option) => option.memberAndSpouseOptionId == builtSelections.add
        );
        builtSelections.addVal = selectedOption.memberAndSpousePremiumAmount;
      } else if (results.addPlan.memberAndDependentsIsSelected) {
        let selectedOption = results.addPlan.options.find(
          (option) => option.memberAndDependentsOptionId == builtSelections.add
        );
        builtSelections.addVal =
          selectedOption.memberAndDependentsPremiumAmount;
      } else if (results.addPlan.familyIsSelected) {
        let selectedOption = results.addPlan.options.find(
          (option) => option.familyOptionId == builtSelections.add
        );
        builtSelections.addVal = selectedOption.familyPremiumAmount;
      }
    }

    let _addPlanSelection = results?.addPlan?.options.map((option) => {
      if (selections.add === option.memberOptionId) return 'member';
      else if (selections.add === option.memberAndSpouseOptionId)
        return 'member and spouse';
      else if (selections.add === option.memberAndDependentsOptionId)
        return 'member and dependents';
      else if (selections.add === option.familyOptionId) {
        let onlyFamilyAndMemberAvailable =
          !option.memberAndDependentsAreEnabled &&
          !option.memberAndSpouseOptionsAreEnabled &&
          option.familyOptionsAreEnabled &&
          option.memberOptionsAreEnabled;
        if (onlyFamilyAndMemberAvailable) return 'familyOptional';
        else return 'family';
      } else return null;
    });
    if (builtSelections.addVal > 0)
      builtLocalBennyRequiredPlans.add = results.addPlan.isBeneficiaryEnabled;

    setAddSelection(_addPlanSelection);

    //hospital
    if (results?.hospitalPlan && results.hospitalPlan.selectedOptionId > 0) {
      builtSelections.hospital = results.hospitalPlan.selectedOptionId;
      if (results.hospitalPlan.memberIsSelected) {
        let selectedOption = results.hospitalPlan.options.find(
          (option) => option.memberOptionId == builtSelections.hospital
        );
        builtSelections.hospitalVal = selectedOption.memberPremiumAmount;
      } else if (results.hospitalPlan.memberAndSpouseIsSelected) {
        let selectedOption = results.hospitalPlan.options.find(
          (option) => option.memberAndSpouseOptionId == builtSelections.hospital
        );
        builtSelections.hospitalVal =
          selectedOption.memberAndSpousePremiumAmount;
      } else if (results.hospitalPlan.memberAndDependentsIsSelected) {
        let selectedOption = results.hospitalPlan.options.find(
          (option) =>
            option.memberAndDependentsOptionId == builtSelections.hospital
        );
        builtSelections.hospitalVal =
          selectedOption.memberAndDependentsPremiumAmount;
      } else if (results.hospitalPlan.familyIsSelected) {
        let selectedOption = results.hospitalPlan.options.find(
          (option) => option.familyOptionId == builtSelections.hospital
        );
        builtSelections.hospitalVal = selectedOption.familyPremiumAmount;
      }
    }

    let _hospitalPlanSelection = results?.hospitalPlan?.options.map(
      (option) => {
        if (selections.hospital === option.memberOptionId) return 'member';
        else if (selections.hospital === option.memberAndSpouseOptionId)
          return 'member and spouse';
        else if (selections.hospital === option.memberAndDependentsOptionId)
          return 'member and dependents';
        else if (selections.hospital === option.familyOptionId) {
          let onlyFamilyAndMemberAvailable =
            !option.memberAndDependentsAreEnabled &&
            !option.memberAndSpouseOptionsAreEnabled &&
            option.familyOptionsAreEnabled &&
            option.memberOptionsAreEnabled;
          if (onlyFamilyAndMemberAvailable) return 'familyOptional';
          else return 'family';
        } else return null;
      }
    );
    if (builtSelections.hospitalVal > 0)
      builtLocalBennyRequiredPlans.hospital =
        results.hospitalPlan.isBeneficiaryEnabled;

    setHospitalSelection(_hospitalPlanSelection);
    if (results?.isEnrollmentOpen) {
      setOpenEnrollment(true);
    } else {
      setOpenEnrollment(false);
    }
    if (results?.isEnrolled) {
      setIsEnrolled(true);
    } else {
      setIsEnrolled(false);
    }

    setSelections(builtSelections);
    setLocalBennyRequiredPlans(builtLocalBennyRequiredPlans);
  }, [results]);

  useEffect(() => {
    if (results && !openEnrollment) {
      history.push('/dashboard');
    }
  }, [openEnrollment]);

  useEffect(() => {
    if (results && isEnrolled) {
      history.push('success', { ...history.location.state });
    }
  }, [isEnrolled]);

  const updateResults = async () => {
    if (fullMemberObj?.dob) {
      let _userInfoCalc = {
        ...userInfo,
        dob: fullMemberObj.dob,
        spouseDOB: spouse?.dateOfBirth,
        OrganizationId: fullMemberObj?.organizationId,
      };
      console.log('user info in UPDATE RESULTS', _userInfoCalc);

      let _thisEnrollment;
      try {
        _thisEnrollment = await calculatorService.updateMemberCalculateRates(
          _userInfoCalc
        );
      } catch (error) {
        console.log('error calculating', error);
      }

      setResults(_thisEnrollment);
    }
  };

  // Checking requirements for 4 or 9 SSN

  const [require9DigitSocial, setRequire9DigitSocial] = useState(false);
  const [fullSocial, setFullSocial] = useState();

  const checkRequireSocial = () => {
    let plans = [];
    for (let [key, value] of Object.entries(results)) {
      if (key.slice(key.length - 4, key.length) === 'Plan' && value != null) {
        plans.push(value);
      }
    }
    let _require9DigitSocial = false;
    for (let plan of plans) {
      //check for user selection (decline or otherwise)
      //ladd doesnt have selectedOptionId, but has totalPremiumAmount on plan level
      if (plan?.totalPremiumAmount > 0) {
        if (plan.require9DigitSocial) _require9DigitSocial = true;
      } else if (
        plan.selectedOptionId != 0 &&
        plan?.options &&
        plan.options.length > 0
      ) {
        for (let option of plan.options) {
          //check that the selected option isnt a decline
          if (
            option.isDecline &&
            option.memberOptionId != plan.selectedOptionId
          ) {
            if (plan.require9DigitSocial) _require9DigitSocial = true;
          }
        }
      }
    }
    setRequire9DigitSocial(_require9DigitSocial);
    // console.log("this person requires a full social:", _require9DigitSocial)
  };

  const getFullSocial = async (e) => {
    try {
      let _fullSocial = await memberService.getMemberSocial();
      if (_fullSocial) setFullSocial(_fullSocial);
      // console.log("fullSoc", _fullSocial);
    } catch (error) {
      console.log('error grabbing full social');
    }
  };

  const setSocial = () => {
    if (require9DigitSocial) {
      setFullMemberObj({
        ...fullMemberObj,
        social: fullSocial?.social,
      });
    } else {
      // console.log("setting the shorter social")
      setFullMemberObj({
        ...fullMemberObj,
        social: fullSocial?.social?.slice(-4),
      });
    }
  };

  useEffect(() => {
    if (results) {
      checkRequireSocial();
    }
  }, [results]);

  // useEffect(() => {
  //   getFullSocial()
  // },[fullMemberObj.id])

  useEffect(() => {
    if (fullSocial?.social) {
      setSocial();
    }
  }, [fullSocial?.social, require9DigitSocial]);

  // useEffect(()=>{
  //   if (!fullSocial && results?.require9DigitSocial) getFullSocial()
  // },[results])

  //Recc state,effects
  const [recommendations, setRecomendations] = useState({});
  const getStoredRecommendations = async () => {
    let _survey = await getStoredSurvey();
    if (_survey) {
      try {
        let _surveyResults;
        _surveyResults = await calculatorService.getRecommendationsLoggedIn(
          _survey
        );
        setRecomendations(_surveyResults);
      } catch (error) {
        console.log('error getting recommendations', error);
      }
    }
  };
  const getStoredSurvey = async (e) => {
    let storedSurvey;

    try {
      storedSurvey = await calculatorService.getRecommendationResponses();
    } catch (error) {
      console.log('error getting stored survey', error);
    }

    if (storedSurvey) {
      if (storedSurvey?.policyCount == null) storedSurvey.policyCount = 0;

      return storedSurvey;
    }
  };
  useEffect(() => {
    getStoredRecommendations();
  }, []);

  //Selections state,handlers,effects
  const [runningTotal, setRunningTotal] = useState(0);
  const [selections, setSelections] = useState({
    std: undefined, //optionId
    stdVal: 0, //PremiumAmount
    ltd: undefined, //optionId
    ltdVal: 0, //PremiumAmount
    ladd: [0, 0, 0], // Coverage Amounts
    laddVal: [0, 0, 0], //Premium amounts
    laddId: undefined, //planId
  });
  const selectionHandler = (e) => {
    setTimeLeft('About 2 minutes left');
    setProgress(40);
    if (e.target != null) {
      let premium = parseFloat(e.target.getAttribute('data-amount'));
      let value = parseFloat(e.target.value);
      let plan = e.target.name;
      let planVal = plan + 'Val';
      setSelections({
        ...selections,
        [plan]: value,
        [planVal]: premium,
      });
    } else {
      let premium = parseFloat(e.getAttribute('data-amount'));
      let value = parseFloat(e.value);
      let plan = e.name;
      let planVal = plan + 'Val';

      setSelections({
        ...selections,
        [plan]: value,
        [planVal]: premium,
      });
    }
  };

  const selectionRec = (options, index) => {
    let value = options[index];
    let newArray = options.map((x) => x);

    //assuming last one is the 'not now' option, pop it off the array.
    newArray.pop();

    newArray.sort((a, b) => {
      return a.memberPremiumAmount - b.memberPremiumAmount;
    });
    let sortedIndex = newArray.findIndex(
      (x) => x.memberOptionId === value.memberOptionId
    );

    if (recommendations.Lowest && sortedIndex == 0) return true;
    if (
      newArray.length > 2 &&
      !recommendations.Lowest &&
      sortedIndex == newArray.length - 2
    )
      return true;
    if (
      newArray.length <= 2 &&
      !recommendations.Lowest &&
      sortedIndex == newArray.length - 1
    )
      return true;
    else return false;
  };

  useEffect(() => {
    let selectionsAsArray = Object.entries(selections);
    let _total = 0;
    for (let [key, value] of selectionsAsArray) {
      //Adding up our monthly cost
      if (typeof value == 'object' && key.includes('Val')) {
        //It's a num array(ladd), so get the total
        let sum = value.reduce(
          (prevValue, currValue) => prevValue + currValue,
          0
        );
        _total += sum;
      } else if (key.includes('Val')) _total += parseFloat(value);
    }
    //WE WANT TO ADD THE TRANS FEE WHEN THEY SELECT THEIR PAY SCHEDULE
    // if (_total > 0) _total += results?.transactionFee;
    setRunningTotal(_total);
  }, [selections]);

  const [calculated, setCalculated] = useState(false);
  // const [calculated,setCalculated] = useState(true) //debug

  //this is whats being passed with thisUserContext
  let contextProps = {
    userInfo: userInfo,
    setUserInfo: () => setUserInfo(),
  };

  // Saving Progress
  let saveLadd = async () => {
    let groupPlanId = results.groupPlanId;
    let planId = selections.laddId;
    let memeberCoverage = selections.ladd[0];
    let spouseCoverage = selections.ladd[1];
    let dependentCoverage = selections.ladd[2];

    try {
      await enrollmentService.saveLADD(
        groupPlanId,
        planId,
        memeberCoverage,
        spouseCoverage,
        dependentCoverage
      );
    } catch (error) {
      console.log('error saving ladd', error);
    }
  };

  let saveCI = async () => {
    let groupPlanId = results.groupPlanId;
    let planId = selections.criticalIllnessId;
    let memeberCoverage = selections.criticalIllness[0];
    let spouseCoverage = selections.criticalIllness[1];
    let dependentCoverage = selections.criticalIllness[2];

    try {
      await enrollmentService.saveLADD(
        groupPlanId,
        planId,
        memeberCoverage,
        spouseCoverage,
        dependentCoverage
      );
    } catch (error) {
      console.log('error saving ladd', error);
    }
  };

  //get gender types
  const [genderTypes, setGenderTypes] = useState([]);

  const getGenderTypes = async () => {
    let _genderTypes = [];
    try {
      _genderTypes = await optionService.getGenderTypes();
    } catch (error) {
      console.log('error getting gender type list', error);
    }
    if (_genderTypes) setGenderTypes(_genderTypes);
  };

  useEffect(() => {
    getGenderTypes();
  }, []);

  //SPOUSE
  const [spouseInDb, setSpouseInDb] = useState({});
  const [spouse, setSpouse] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    email: '',
    gender: 0,
    informationAuthorize: false,
    coverageAuthorize: null,
    spouseInDb: false, //Dont send this property back
  });

  const [spouseUpdateTimer, setSpouseUpdateTimer] = useState(null);
  const [spouseRatesLoading, setSpouseRatesLoading] = useState(false);
  //if a plan has spouse rates, we need to be able to refresh the results to get update rates
  useEffect(() => {
    clearTimeout(spouseUpdateTimer);
    if (spouseRatesRequired) {
      let timerId = setTimeout(() => {
        updateResults();
        setSpouseRatesLoading(false);
      }, 800);
      setSpouseRatesLoading(true);
      setSpouseUpdateTimer(timerId);
    }
  }, [spouse.dateOfBirth]);

  const getSpouse = async (e) => {
    let _spouse;
    try {
      _spouse = await memberService.getSpouse();
      if (_spouse) {
        _spouse.spouseInDb = true;
      } else {
        _spouse = {
          firstName: '',
          lastName: '',
          dateOfBirth: null,
          email: '',
          informationAuthorize: false,
          coverageAuthorize: null,
          spouseInDb: false,
        };
      }
    } catch (error) {
      _spouse = {
        firstName: '',
        lastName: '',
        dateOfBirth: null,
        email: '',
        gender: 0,
        informationAuthorize: false,
        coverageAuthorize: null,
        spouseInDb: false,
      };
      setSpouse(_spouse);
    }

    //format dob from db debending on device
    let formattedDob = null;
    if (_spouse?.dateOfBirth) {
      //format dob w/ timezone
      const dobWithTimezone = formatUTCDateToObjWithTimeZone(
        _spouse?.dateOfBirth
      );
      //format further for device

      // if(isMobileDevice){
      //   formattedDob = dobWithTimezone //MUI datepicker format
      // }else{
      //   formattedDob = format(new Date(dobWithTimezone), "yyyy-MM-dd"); //MUI textfield format
      // }

      formattedDob = dobWithTimezone;
    }
    setSpouse({ ..._spouse, dateOfBirth: formattedDob });
    setSpouseInDb({ ..._spouse });
  };

  let saveSpouseInfo = async () => {
    //update existing spouse (existing in db if they have an id and spouseInDb is true)
    if (
      spouse.spouseInDb &&
      JSON.stringify(spouse) != JSON.stringify(spouseInDb) &&
      spouse.id
    ) {
      let updateSpouseObj = { ...spouse };
      delete updateSpouseObj.spouseInDb;

      try {
        await memberService.updateSpouse(updateSpouseObj);
      } catch (error) {
        console.error('error updating spouse', error);
      }
    } else if (!spouse.spouseInDb && spouse.firstName != '' && !spouse.id) {
      let addSpouseObj = { ...spouse };
      delete addSpouseObj.spouseInDb;
      try {
        await memberService.addSpouse(addSpouseObj);
      } catch (error) {
        console.error('error adding spouse', error);
      }
    }

    //check if spouse exists as a beneficiary AND if spouse values have changed - if yes, update spouse info in the beneficiary table

    let spouseIsBeneficiaryInDb = beneficiariesInDB?.find((benny) => {
      return benny.relationshipTypeId === 1;
    });

    if (
      spouseIsBeneficiaryInDb &&
      JSON.stringify(spouse) != JSON.stringify(spouseInDb)
    ) {
      let _updatedSpouseBennyObj = {
        firstName: spouse.firstName,
        middleInitial: spouseIsBeneficiaryInDb?.middleInitial || '',
        lastName: spouse.lastName,
        dob: spouse.dateOfBirth,
        memberId: fullMemberObj.id,
        id: spouseIsBeneficiaryInDb?.id,
        relationshipTypeId: 1,
        isDeleted: spouseIsBeneficiaryInDb?.isDeleted || false,
        lastModifiedBy: spouseIsBeneficiaryInDb?.lastModifiedBy,
        amount: spouseIsBeneficiaryInDb?.amount,
      };
      try {
        await enrollmentService.updateBeneficiary(_updatedSpouseBennyObj);
      } catch (error) {
        console.error('error updating spouse benny', error);
      }
    }

    //refresh spouse and beneficiaries
    await getSpouse();
  };

  //DEPENDENTS
  const [dependentsInDB, setDependentsInDB] = useState([]);
  const [dependents, setDependents] = useState([]);
  const getDependents = async () => {
    let _dependents;
    try {
      _dependents = await memberService.getDependents();
    } catch (error) {
      console.log('error getting deps', error);
    }
    let _dependentsInDB = [];

    _dependents.forEach((_dependent) => {
      //format dob from db debending on device
      let formattedDob = null;
      if (_dependent?.dob) {
        // _dependent.dob = formatUTCDateToObjWithTimeZone(_dependent?.dob)
        const dobWithTimezone = formatUTCDateToObjWithTimeZone(_dependent?.dob);
        //format further for device

        // if(isMobileDevice){
        //   formattedDob = dobWithTimezone //MUI datepicker format
        // }else{
        //   formattedDob = format(new Date(dobWithTimezone), "yyyy-MM-dd"); //MUI textfield format
        // }
        formattedDob = dobWithTimezone;
      }
      _dependent.dob = formattedDob;
      _dependent.dobError = null;
      _dependentsInDB.push({ ..._dependent, dob: formattedDob });
    });

    setDependentsInDB(_dependentsInDB);
    setDependents(_dependents);
  };

  const [spouseAndDepsLoaded, setSpouseAndDepsLoaded] = useState(false);
  useEffect(() => {
    if (!spouseAndDepsLoaded && fullMemberObj.id) {
      getDependents();
      getSpouse();
      setSpouseAndDepsLoaded(true);
    }
  }, [fullMemberObj]);

  //Grab spouse changes
  useEffect(() => {
    // console.log('spouse or deps changing in props')
    if (spouseAndDepsLoaded) {
      getSpouse();
      getDependents();
      getInitialBeneficiaries();
    }
    // getInitialBeneficiaries()
  }, [fullMemberObj?.id]);

  let saveDependents = async () => {
    // console.log('saving deps, compare db state with edited state')
    for (let editDependent of dependents) {
      let dependentInDB = dependentsInDB.find(
        (DBdependent) => DBdependent.id == editDependent.id
      );

      //check for delete dep
      if (dependentInDB && editDependent.isDeleted != dependentInDB.isDeleted) {
        try {
          await memberService.deleteDependent(editDependent.id);
        } catch (error) {
          console.log('error deleting dep', error);
        }
      }
      //check for updated dep
      else if (
        dependentInDB &&
        JSON.stringify(editDependent) != JSON.stringify(dependentInDB)
      ) {
        // console.log('updated On the front')
        delete editDependent?.uid;
        delete editDependent?.dobError;
        try {
          await memberService.updateDependent(editDependent);
        } catch (error) {
          console.log('error updating dep', error);
        }
      }

      //check for new dep
      else if (!dependentInDB && !editDependent.isDeleted) {
        // console.log('added dep on front')
        delete editDependent?.uid;
        delete editDependent?.dobError;
        try {
          await memberService.addDependent(editDependent);
        } catch (error) {
          console.log('error adding dep', error);
        }
      }
    }

    //future logic - check if dependent is a beneficiary- if yes, update dependent info in the beneficiary table
    getDependents();
  };

  //BENNY LOGIC
  const [benficiariesObj, setBeneficiariesObj] = useState({});
  //if it's checked in primary, remove it from contingent
  const [primaryCheckboxes, setPrimaryCheckboxes] = useState([]);

  const [contingentCheckboxes, setContingentCheckboxes] = useState([]);

  //State arrays to display, handle adding to 'SentBack' via checkbox
  const [primaryBeneficiaries, setPrimaryBeneficiaries] = useState([]);
  // console.log(primaryBeneficiaries)
  const [contingentBeneficiaries, setContingentBeneficiaries] = useState([]);

  //Initial list of potential benny's builts from spouse/dep, combined with benny's in db already
  const [beneficiariesInDB, setBeneficiariesInDB] = useState([]);

  const [potentialBeneficiaries, setPotentialBeneficiaries] = useState();
  const [storedAllocations, setStoredAllocations] = useState([]);

  const getInitialBeneficiaries = async () => {
    //Check for isBeneficiary
    let plans = [];
    if (results) {
      for (let [key, value] of Object.entries(results)) {
        if (key.slice(key.length - 4, key.length) === 'Plan' && value != null) {
          plans.push(value);
        }
      }
    }
    let electedBennyRequiredPlanIds = [];
    for (let plan of plans) {
      //check for user selection (decline or otherwise)
      //ladd doesnt have selectedOptionId, but has totalPremiumAmount on plan level
      if (plan?.totalPremiumAmount > 0) {
        if (plan.isBeneficiaryEnabled)
          electedBennyRequiredPlanIds.push(plan.planId);
      } else if (
        plan.selectedOptionId != 0 &&
        plan?.options &&
        plan.options.length > 0
      ) {
        for (let option of plan.options) {
          //check that the selected option isnt a decline
          if (
            option.isDecline &&
            plan.selectedOptionId != option.memberOptionId
          ) {
            if (plan.isBeneficiaryEnabled)
              electedBennyRequiredPlanIds.push(plan.planId);
          }
        }
      }
    }

    let laddIdOverRide = null;
    if (results?.laddPlan?.totalPremiumAmount > 0)
      laddIdOverRide = results?.laddPlan?.planId;

    let planIdForCall = laddIdOverRide
      ? laddIdOverRide
      : electedBennyRequiredPlanIds[0];

    //GET BENEFICIARIES
    let _beneficiariesInDB = [];
    try {
      _beneficiariesInDB = await enrollmentService.getBeneficiaries();
    } catch (error) {
      console.log('error getting bennies', error);
    }
    //check for 'deleted beneficiaries' and do not display
    let filteredBeneficiariesInDB = _beneficiariesInDB.filter(
      (benny) => !benny.isDeleted
    );
    setBeneficiariesInDB(filteredBeneficiariesInDB);

    //GET POTENTIAL BENEFICIARIES - these can be duplicates of beneficiaries from above, can also include dependents and spouse if they are not already selected as beneficiaries
    let _potentials;
    try {
      _potentials = await enrollmentService.getPotentialBeneficiaries();
    } catch (error) {
      console.log('error getting potential bennies', error);
    }
    setPotentialBeneficiaries(_potentials);

    //Filter out existing bennies in db from potentials
    let initialBuiltArray = [];
    for (let beneficiary of filteredBeneficiariesInDB) {
      initialBuiltArray.push({ ...beneficiary });
    }
    for (let potentialBeneficiary of _potentials) {
      let potentialFirstName = potentialBeneficiary.firstName;
      let potentialLastName = potentialBeneficiary.lastName;

      //determine if in potential list based on first and last names. loop through built array which has db bennies, if the potential doesnt match, push it to the built array so it can be an additional option to select
      let potentialNotInDb = true;
      for (let dbBeneficiary of initialBuiltArray) {
        let dbFirstName = dbBeneficiary.firstName;
        let dbLastName = dbBeneficiary.lastName;

        if (
          potentialFirstName == dbFirstName &&
          potentialLastName == dbLastName
        ) {
          potentialNotInDb = false;
        }
      }

      if (potentialNotInDb) {
        initialBuiltArray.push({ ...potentialBeneficiary });
      }
    }
    // console.log('initial built array', initialBuiltArray)
    setPrimaryBeneficiaries(initialBuiltArray);

    //Initial Contingents
    let initialContingents = [];
    initialBuiltArray.forEach((benny) => {
      // if (benny.relationshipTypeId != 1) initialContingents.push({ ...benny });
      initialContingents.push({ ...benny });
    });
    setContingentBeneficiaries(initialContingents);

    //get allocations from db
    let allocationsExist = false;
    if (planIdForCall > 0) {
      try {
        console.log('planIdForCall', planIdForCall, laddIdOverRide, results);
        allocationsExist = await enrollmentService.getBeneficiaryAllocations(
          planIdForCall
        );
      } catch (error) {
        console.log('error checking for allocations', error);
      }
    }

    //set stored allocations
    if (allocationsExist) setStoredAllocations(allocationsExist);

    //CREATING CHECKED BENEFICIARY arrays
    let primaryInitialChecks = [];
    let contingentInitialChecks = [];
    // console.log('allocations exist', allocationsExist)
    if (allocationsExist?.length > 0) {
      primaryInitialChecks = [];
      for (let bennyObj of allocationsExist) {
        let allocationObj;
        allocationObj = {
          id: bennyObj.id,
          PlanId: bennyObj.planId,
          // BeneficiaryId: "",
          amount: bennyObj.amount,
          isPrimary: bennyObj.isPrimary,
          // IsDeleted: false,
          checked: true,
        };
        //push to primary
        if (allocationObj.isPrimary) {
          primaryInitialChecks.push(allocationObj);
          //push to contingent
        } else {
          console.log('pushing to contingent', allocationObj);
          contingentInitialChecks.push(allocationObj);
        }
      }

      // console.log('mega initial contingent checks',contingentInitialChecks)
      //comparing allocations we have in db to the initial built array

      //PRIMARIES ////////////////////////////////////
      if (initialBuiltArray?.length > 0) {
        //check in initial built array
        for (let bennyObj of initialBuiltArray) {
          let allocationObj;
          if (bennyObj.id) {
            if (primaryInitialChecks.find((check) => check.id == bennyObj.id))
              continue;
            allocationObj = {
              id: bennyObj.id,
              PlanId: planIdForCall,
              // BeneficiaryId: "",
              amount: 0,
              isPrimary: true,
              // IsDeleted: false,
              checked: false,
            };
            //default spouse allocation IF spouse benny and allocation does not already exist in db
            if (bennyObj.relationshipTypeId === 1) {
              allocationObj.amount = 100;
              allocationObj.checked = true;
            }
          } else if (bennyObj.dependentId) {
            //if potential benny, not in db as actual beneficiary & has a dependentId
            if (
              primaryInitialChecks.find(
                (check) => check.dependentId == bennyObj.dependentId
              )
            )
              continue;
            allocationObj = {
              dependentId: bennyObj.dependentId,
              PlanId: planIdForCall,
              // BeneficiaryId: "",
              amount: 0,
              isPrimary: true,
              // IsDeleted: false,
              checked: false,
            };
            if (bennyObj.relationshipTypeId === 1) {
              allocationObj.amount = 100;
              allocationObj.checked = true;
            }
          }
          primaryInitialChecks.push(allocationObj);
        }
      }
      //CONTINGENTS ///////////////////
      if (initialContingents?.length > 0) {
        for (let bennyObj of initialContingents) {
          let allocationObj;
          if (bennyObj.id) {
            //if existing contingent bennies, skip over creating allocatioObj as this data exists
            if (
              contingentInitialChecks.find((check) => check.id == bennyObj.id)
            )
              continue;
            allocationObj = {
              id: bennyObj.id,
              PlanId: planIdForCall,
              // BeneficiaryId: "",
              amount: 0,
              isPrimary: false,
              // IsDeleted: false,
              checked: false,
            };
          } else if (bennyObj.dependentId) {
            //if potential benny, not in db as actual beneficiary & has a dependentId
            if (
              contingentInitialChecks.find(
                (check) => check.dependentId == bennyObj.dependentId
              )
            )
              continue;
            allocationObj = {
              dependentId: bennyObj.dependentId,
              // PlanId: props.results?.lifeADDPlan?.planId,
              // BeneficiaryId: "",
              amount: 0,
              isPrimary: false,
              // IsDeleted: false,
              checked: false,
            };
            //if spouse and no amount exists yet and there are NO other beneficiaries in the db, set to 100%
            if (
              bennyObj.relationshipTypeId == 1 &&
              (!bennyObj.amount || bennyObj.amount == 0) &&
              !bennyObj.isDeleted
            ) {
              allocationObj.amount = 100;
              allocationObj.checked = true;
            } else if (
              bennyObj.relationshipTypeId === 1 &&
              bennyObj.amount > 0
            ) {
              allocationObj.amount = bennyObj.amount;
              allocationObj.checked = true;
            }
          }
          contingentInitialChecks.push(allocationObj);
        }
      }
      // console.log('0 initial contingent checks',contingentInitialChecks)
    } else {
      console.log('***************no allocations exist');
      primaryInitialChecks = [];

      for (let bennyObj of initialBuiltArray) {
        let allocationObj;
        if (bennyObj.id) {
          allocationObj = {
            id: bennyObj.id,
            // PlanId: props.results?.lifeADDPlan?.planId,
            // BeneficiaryId: "",
            amount: 0,
            // isPrimary: true,
            // IsDeleted: false,
            checked: false,
          };
          //if spouse and no amount exists yet and there are NO other beneficiaries in the db, set to 100%
          if (
            bennyObj.relationshipTypeId == 1 &&
            (!bennyObj.amount || bennyObj.amount == 0)
          ) {
            allocationObj.amount = 100;
            allocationObj.checked = true;
          } else if (bennyObj.relationshipTypeId === 1 && bennyObj.amount > 0) {
            allocationObj.amount = bennyObj.amount;
            allocationObj.checked = true;
          }
        } else if (bennyObj.dependentId) {
          allocationObj = {
            dependentId: bennyObj.dependentId,
            // PlanId: props.results?.lifeADDPlan?.planId,
            // BeneficiaryId: "",
            amount: 0,
            // isPrimary: true,
            // IsDeleted: false,
            checked: false,
          };
          //if spouse and no amount exists yet and there are NO other beneficiaries in the db, set to 100%
          if (bennyObj.relationshipTypeId == 1) {
            allocationObj.amount = 100;
            allocationObj.checked = true;
          } else if (bennyObj.relationshipTypeId === 1 && bennyObj.amount > 0) {
            allocationObj.amount = bennyObj.amount;
            allocationObj.checked = true;
          }
        }
        primaryInitialChecks.push(allocationObj);
      }

      //filter out contingentInitialChecks
      contingentInitialChecks = primaryInitialChecks.filter((benny) => {
        return !benny.checked;
      });
    }

    setContingentCheckboxes(contingentInitialChecks);
    setPrimaryCheckboxes(primaryInitialChecks);
    // console.log('FINAL initial contingent checks',contingentInitialChecks)
  };

  ///get all allocations from ALL potential plan ids that require beneficiaries, create array of all planIds with existing allocations
  const [plansWithSavedAllocations, setPlansWithSavedAllocations] = useState(
    []
  );
  const getAllPlanIdsWithAllocations = async () => {
    let _plansWithSavedAllocations = [];
    let electedBennyRequiredPlanIds = buildBennyPlanIdArray();
    if (electedBennyRequiredPlanIds?.length > 0) {
      for (let planId of electedBennyRequiredPlanIds) {
        let allocationsExist = false;
        try {
          allocationsExist = await enrollmentService.getBeneficiaryAllocations(
            planId
          );
        } catch (error) {
          console.log('error checking for allocations', error);
        }
        //if allocations in db associated with plan id, push to array
        if (allocationsExist?.length > 0)
          _plansWithSavedAllocations.push(planId);
      }
      console.log('plansWithSavedAllocations', _plansWithSavedAllocations);
      setPlansWithSavedAllocations(_plansWithSavedAllocations);
    }
  };

  //refresh planIds with allocations if bennies in db change
  useEffect(() => {
    if (results && fullMemberObj?.id) {
      getAllPlanIdsWithAllocations();
    }
  }, [beneficiariesInDB?.length]);

  const [primaryBennyAdd, setPrimaryBennyAdd] = useState(false);
  const [contingentBennyAdd, setContingentBennyAdd] = useState(false);

  const buildSaveBackArray = async () => {
    let _builtArray = [];

    //build from primary
    let index = 0;
    if (primaryCheckboxes?.length > 0) {
      for (let primary of primaryCheckboxes) {
        if (primary.checked) {
          if (index == 0 && primary.amount == 33.33) {
            primary.amount = 33.34;
          }
          let infoId;
          let potential = false;

          if (primary.dependentId) {
            infoId = primary.dependentId;
            potential = true;
          } else if (primary.id) infoId = primary.id;

          if (!potential) {
            let builtBenny = {
              PlanId: results?.lifeADDPlan?.planId || 0,
              BeneficiaryId: infoId,
              Amount: primary.amount,
              isPrimary: true,
              IsDeleted: false,
            };
            _builtArray.push(builtBenny);
          } else {
            let potentialBenny = primaryBeneficiaries.find((beneficiary) => {
              return beneficiary.dependentId == infoId;
            });

            let insertBenny = {
              FirstName: potentialBenny.firstName,
              MiddleInitial: potentialBenny.MiddleInitial || '',
              LastName: potentialBenny.lastName,
              RelationShipTypeId: potentialBenny.relationshipTypeId,
              // dob: potentialBenny.dob,
              IsDeleted: false,
            };
            if (potentialBenny.dob) insertBenny.dob = potentialBenny.dob;
            // console.log(insertBenny)
            let newBenny;
            try {
              newBenny = await enrollmentService.addBeneficiary(insertBenny);
            } catch (error) {
              console.log('error adding benny', error);
            }

            let builtBenny = {
              PlanId: results?.lifeADDPlan?.planId || 0,
              BeneficiaryId: newBenny.id,
              Amount: primary.amount,
              isPrimary: true,
              IsDeleted: false,
            };
            _builtArray.push(builtBenny);
          }
          index++;
        }
      }
      // console.log("built array from primary", _builtArray);
    }

    //build from contingent
    let contingentIndex = 0;
    if (contingentCheckboxes?.length > 0) {
      for (let contingent of contingentCheckboxes) {
        if (contingent.checked) {
          if (contingentIndex == 0 && contingent.amount == 33.33) {
            contingent.amount = 33.34;
          }
          let infoId;
          let potential = false;

          if (contingent.dependentId) {
            infoId = contingent.dependentId;
            potential = true;
          } else if (contingent.id) infoId = contingent.id;

          if (!potential) {
            let builtBenny = {
              PlanId: results?.lifeADDPlan?.planId || 0,
              BeneficiaryId: infoId,
              Amount: contingent.amount,
              isPrimary: false,
              IsDeleted: false,
            };
            _builtArray.push(builtBenny);
          } else {
            let potentialBenny = contingentBeneficiaries.find((beneficiary) => {
              return beneficiary.dependentId == infoId;
            });

            let insertBenny = {
              FirstName: potentialBenny.firstName,
              MiddleInitial: potentialBenny.MiddleInitial || '',
              LastName: potentialBenny.lastName,
              RelationShipTypeId: potentialBenny.relationshipTypeId,
              // dob: potentialBenny.dob || null,
              IsDeleted: false,
            };
            if (potentialBenny.dob) insertBenny.dob = potentialBenny.dob;
            // console.log(insertBenny)
            let newBenny;
            try {
              newBenny = await enrollmentService.addBeneficiary(insertBenny);
            } catch (error) {
              console.log('error adding contingent benny', error);
            }

            let builtBenny = {
              PlanId: results?.lifeADDPlan?.planId || 0,
              BeneficiaryId: newBenny.id,
              Amount: contingent.amount,
              isPrimary: false,
              IsDeleted: false,
            };
            _builtArray.push(builtBenny);
          }
          contingentIndex++;
        }
      }
    }
    // console.log(_builtArray)
    return _builtArray;
    // await enrollmentService.saveBeneficiaryAllocation(_builtArray)
  };

  const [benniesLoaded, setBenniesLoaded] = useState(false);
  useEffect(() => {
    if (!benniesLoaded && fullMemberObj?.id && results) {
      getInitialBeneficiaries();
      setBenniesLoaded(true);
    }
  }, [fullMemberObj, results, spouse]);

  // useEffect(() => {
  //   getInitialBeneficiaries();
  // }, [results, spouse]);

  const handleLaddSaveAndContinue = async () => {
    await saveLadd();
    await saveSpouseInfo();
    await saveDependents();
    await updateResults();
    await getInitialBeneficiaries();
  };

  const buildBennyPlanIdArray = () => {
    //Check for isBeneficiary
    let plans = [];
    for (let [key, value] of Object.entries(results)) {
      if (key.slice(key.length - 4, key.length) === 'Plan' && value != null) {
        plans.push(value);
      }
    }
    let electedBennyRequiredPlanIds = [];
    for (let plan of plans) {
      //check for user selection (decline or otherwise)
      //ladd doesnt have selectedOptionId, but has totalPremiumAmount on plan level
      if (plan?.totalPremiumAmount > 0) {
        if (plan.isBeneficiaryEnabled)
          electedBennyRequiredPlanIds.push(plan.planId);
      } else if (
        plan.selectedOptionId != 0 &&
        plan?.options &&
        plan.options.length > 0
      ) {
        for (let option of plan.options) {
          //check that the selected option isnt a decline
          if (
            option.isDecline &&
            plan.selectedOptionId != option.memberOptionId
          ) {
            if (plan.isBeneficiaryEnabled)
              electedBennyRequiredPlanIds.push(plan.planId);
          }
        }
      }
    }
    return electedBennyRequiredPlanIds;
  };

  const [scrollIntoView, setScrollIntoView] = useState(false);

  const [localBennyRequiredPlans, setLocalBennyRequiredPlans] = useState({});
  const [localSpouseRatePlans, setLocalSpouseRatePlans] = useState({});
  useEffect(() => {
    if (selections) {
      let builtLocalBennyRequiredPlans = {
        std: false,
        ltd: false,
        ladd: false,
        accident: false,
        cancer: false,
        add: false,
        hospital: false,
      };

      let builtLocalSpouseRatePlans = {
        std: false,
        ltd: false,
        ladd: false,
        accident: false,
        cancer: false,
        add: false,
        hospital: false,
      };

      //std
      if (results?.stdPlan && selections.stdVal > 0) {
        builtLocalBennyRequiredPlans.std = results.stdPlan.isBeneficiaryEnabled;
        builtLocalSpouseRatePlans.std = results.stdPlan.spouseRatesBasedOn == 1;
      } else {
        builtLocalBennyRequiredPlans.std = false;
        builtLocalSpouseRatePlans.std = false;
      }

      //ltd
      if (results?.ltdPlan && selections.ltdVal > 0) {
        builtLocalBennyRequiredPlans.ltd = results.ltdPlan.isBeneficiaryEnabled;
        builtLocalSpouseRatePlans.ltd = results.ltdPlan.spouseRatesBasedOn == 1;
      } else {
        builtLocalBennyRequiredPlans.ltd = false;
        builtLocalSpouseRatePlans.ltd = false;
      }

      //ladd
      if (results?.lifeADDPlan && selections.laddVal[0] > 0) {
        builtLocalBennyRequiredPlans.ladd =
          results.lifeADDPlan.isBeneficiaryEnabled;
        builtLocalSpouseRatePlans.ladd =
          results.lifeADDPlan.spouseRatesBased == 1;
      } else {
        builtLocalBennyRequiredPlans.ladd = false;
        builtLocalSpouseRatePlans.ladd = false;
      }

      //accident
      if (results?.accidentPlan && selections.accidentVal > 0) {
        builtLocalBennyRequiredPlans.accident =
          results.accidentPlan.isBeneficiaryEnabled;
        builtLocalSpouseRatePlans.accident =
          results.accidentPlan.spouseRatesBasedOn == 1;
      } else {
        builtLocalBennyRequiredPlans.accident = false;
        builtLocalSpouseRatePlans.accident = false;
      }

      //cancer
      if (results?.cancerPlan && selections.cancerVal > 0) {
        builtLocalBennyRequiredPlans.cancer =
          results.cancerPlan.isBeneficiaryEnabled;
        builtLocalSpouseRatePlans.cancer =
          results.cancerPlan.spouseRatesBasedOn == 1;
      } else {
        builtLocalBennyRequiredPlans.cancer = false;
        builtLocalSpouseRatePlans.cancer = false;
      }

      //add
      if (results?.addPlan && selections.addVal > 0) {
        builtLocalBennyRequiredPlans.add = results.addPlan.isBeneficiaryEnabled;
        builtLocalSpouseRatePlans.add = results.addPlan.spouseRatesBasedOn == 1;
      } else {
        builtLocalBennyRequiredPlans.add = false;
        builtLocalSpouseRatePlans.add = false;
      }

      //hospital
      if (results?.hospitalPlan && selections.hospitalVal > 0) {
        builtLocalBennyRequiredPlans.hospital =
          results.hospitalPlan.isBeneficiaryEnabled;
        builtLocalSpouseRatePlans.hospital =
          results.hospitalPlan.spouseRatesBasedOn == 1;
      } else {
        builtLocalBennyRequiredPlans.hospital = false;
        builtLocalSpouseRatePlans.hospital = false;
      }
      setLocalBennyRequiredPlans(builtLocalBennyRequiredPlans);
      setLocalSpouseRatePlans(builtLocalSpouseRatePlans);
    }
  }, [selections]);

  const checkIfBennyRequired = () => {
    let required = false;
    for (let [key, value] of Object.entries(localBennyRequiredPlans)) {
      if (value) required = true;
    }
    setBenRequired(required);

    return required;
  };

  const checkIfSpouseBasedRates = () => {
    let spouseBasedRates = false;
    for (let [key, value] of Object.entries(localSpouseRatePlans)) {
      if (value) spouseBasedRates = true;
    }
    setSpouseRatesRequired(spouseBasedRates);

    return spouseBasedRates;
  };
  useEffect(() => {
    checkIfSpouseBasedRates();
  }, [localSpouseRatePlans]);

  let handleNextClick = async (path) => {
    setLoading(true);
    if (path == 'beneficiaries') {
      await saveLadd();
      await saveSpouseInfo();
      await saveDependents();
      await updateResults();
      await getInitialBeneficiaries();

      setLoading(false);

      //if member isn't selected, ladd is declined
      if (checkIfBennyRequired() == false) {
        setTimeLeft('About 1 minute left');
        setProgress(80);
        history.push('personalinfo', { ...history.location.state });
      } else {
        setTimeLeft('Less than 2 minutes left');
        setProgress(60);
        history.push(path, { ...history.location.state });
      }
    }
    if (path == 'payment') {
      try {
        await memberService.updateMember(fullMemberObj);
      } catch (error) {
        console.log('error updating member', error);
      }
      //update member organization after next click from personal info page
      console.log('updating meberOrg on member side');
      let _updatedMemberOrg = {
        memberId: fullMemberObj?.id,
        organizationId: fullMemberObj?.organizationId,
        isPrimary: true,
      };
      try {
        await memberService.updateMemberOrganizationMember(_updatedMemberOrg);
      } catch (error) {
        console.error('error updating member organization', error);
      }

      handlePhoneInsert();
      //backendvalidation
      let [updatedEnrollmentObj, allowThrough] = await checkBackendValidation();
      if (allowThrough) {
        setLoading(false);
        if (passPersonalValidation()) {
          history.push(path, { ...history.location.state });
        } else {
          setShowErrors(true);
          setScrollIntoView(true);
          setTimeout(() => {
            setScrollIntoView(false);
          }, 1000);
          // console.log("Howdy");
        }
      } else {
        setLoading(false);
        setResults(updatedEnrollmentObj);
        history.push('plans', { ...history.location.state });
      }
    }
    if (path == 'personalinfo') {
      await saveSpouseInfo();
      setTimeLeft('About 1 minute left');
      setProgress(80);
      let builtAllocations = await buildSaveBackArray();
      //check for changes between new allocations and old before updating db
      let newAllocations = builtAllocations?.map((allocation) => {
        return {
          id: allocation.BeneficiaryId,
          amount: allocation.Amount,
        };
      });
      let oldAllocations = storedAllocations?.map((allocation) => {
        return {
          id: allocation.id,
          amount: allocation.amount,
        };
      });
      //check for changes between planIds selected with planIDs stored - want to update even if allocation amounts do not change but new planId that requires bennies is selected
      let electedBennyRequiredPlanIds = buildBennyPlanIdArray();
      let changedPlanIds = false;
      if (
        plansWithSavedAllocations?.length !==
        electedBennyRequiredPlanIds?.length
      ) {
        changedPlanIds = true;
      } else {
        //create sets of each array to compare
        let setPlanIdsInDb = new Set(plansWithSavedAllocations);
        let setPlanIdsElected = new Set(electedBennyRequiredPlanIds);
        if (setPlanIdsInDb.size !== setPlanIdsElected.size)
          changedPlanIds = true;
        for (let planId of setPlanIdsElected) {
          if (!setPlanIdsInDb.has(planId)) changedPlanIds = true;
        }
      }
      // console.log('elected benny required plan ids', electedBennyRequiredPlanIds)
      // console.log('plan ids will allocations in db' , plansWithSavedAllocations)
      if (
        (builtAllocations.length > 0 &&
          JSON.stringify(newAllocations) !== JSON.stringify(oldAllocations)) ||
        changedPlanIds
      ) {
        //send for each planId
        for (let planId of electedBennyRequiredPlanIds) {
          //overWrite planId
          for (let allocation of builtAllocations) {
            allocation.PlanId = planId;
          }
          try {
            // console.log("attempting to save benny allocations agent enroll", builtAllocations)
            await enrollmentService.saveBeneficiaryAllocation(builtAllocations);
          } catch (error) {
            console.log('error saving beneficiary allocation', error);
          }
        }
        await getInitialBeneficiaries();
      }

      setLoading(false);
      history.push(path, { ...history.location.state });
    }
    setLoading(false);
    getFullMemberObj();
  };

  const [guid, setGuid] = useState();

  // Next Button Validation for Plan Selections
  const [planInfoMissing, setPlanInfoMissing] = useState(true);
  const [benRequired, setBenRequired] = useState(false);
  const [spouseRatesRequired, setSpouseRatesRequired] = useState(false);
  const [laddSelectionMade, setLaddSelectionMade] = useState(false);
  useEffect(() => {
    //If there's no ladd plan, set this to true to allow validation
    if (results && !results.lifeADDPlan) {
      // console.log("no life plan");
      setLaddSelectionMade(true);
    }
  }, [results?.lifeADDPlan]);

  useEffect(() => {
    // Setting valid selections if STD, LTD, or accident is elected or declined, but LADD is saving 0 for declined so this logic wont work
    //if std plan exists AND value has been selected
    let _stdPlansValidated = false;
    let _ltdPlansValidated = false;
    let _laddPlansValidated = false;
    let _accidentPlansValidated = false;
    let _cancerPlansValidated = false;
    let _addPlansValidated = false;
    let _hospitalPlansValidated = false;
    if (results?.stdPlan) {
      selections.std
        ? (_stdPlansValidated = false)
        : (_stdPlansValidated = true);
    } else _stdPlansValidated = false;
    //if ltd plan exists AND value has been selected
    if (results?.ltdPlan) {
      selections.ltd
        ? (_ltdPlansValidated = false)
        : (_ltdPlansValidated = true);
    } else _ltdPlansValidated = false;

    //if accident plans exist
    //accident plan not required, but if a spouse, dependent, or family is selected, must fill out spouse and dependent info
    if (results?.accidentPlan) {
      //if member and dependent(s) selected must fill out dependent info
      if (
        selections.accident &&
        accidentSelection[0] === 'member and dependents'
      ) {
        dependents &&
          dependents
            .filter((dep) => !dep.isDeleted)
            .forEach((dep) => {
              if (
                dep.firstName?.length > 0 &&
                dep.lastName?.length > 0 &&
                dep.dob &&
                JSON.stringify(dep.dob).length > 2
              ) {
                _accidentPlansValidated = false;
              } else {
                _accidentPlansValidated = true;
              }
            });
      }

      //if member and spouse selected, must fill out spouse info
      if (selections.accident && accidentSelection[0] === 'member and spouse') {
        spouse.firstName?.length > 0 &&
        spouse.lastName?.length > 0 &&
        spouse.dateOfBirth &&
        JSON.stringify(spouse.dateOfBirth).length > 2
          ? (_accidentPlansValidated = false)
          : (_accidentPlansValidated = true);
      }

      // if family selected, must fill out spouse and dependent info
      if (selections.accident && accidentSelection[0] === 'family') {
        //check all dependents AND Spouse
        dependents &&
          dependents
            .filter((dep) => !dep.isDeleted)
            .forEach((dep) => {
              if (
                dep.firstName?.length > 0 &&
                dep.lastName?.length > 0 &&
                dep.dob &&
                JSON.stringify(dep.dob).length > 2 &&
                spouse.firstName?.length > 0 &&
                spouse.lastName?.length > 0 &&
                spouse.dateOfBirth &&
                JSON.stringify(spouse.dateOfBirth).length > 2
              ) {
                _accidentPlansValidated = false;
              } else {
                _accidentPlansValidated = true;
              }
            });
      }

      //member only option selected
      if (
        accidentSelection &&
        (accidentSelection[0] === 'member' || !accidentSelection[0])
      ) {
        selections.accident
          ? (_accidentPlansValidated = false)
          : (_accidentPlansValidated = true);
      }
    } else _accidentPlansValidated = false;

    //if accident plan not selected
    if (!selections.accident) _accidentPlansValidated = false;

    //if cancer plans exist

    if (results?.cancerPlan) {
      //if member and dependent(s) selected must fill out dependent info
      if (selections.cancer && cancerSelection[0] === 'member and dependents') {
        dependents &&
          dependents
            .filter((dep) => !dep.isDeleted)
            .forEach((dep) => {
              if (
                dep.firstName?.length > 0 &&
                dep.lastName?.length > 0 &&
                dep.dob &&
                JSON.stringify(dep.dob).length > 2
              ) {
                _cancerPlansValidated = false;
              } else {
                _cancerPlansValidated = true;
              }
            });
      }

      //if member and spouse selected, must fill out spouse info
      if (selections.cancer && cancerSelection[0] === 'member and spouse') {
        spouse.firstName?.length > 0 &&
        spouse.lastName?.length > 0 &&
        spouse.dateOfBirth &&
        JSON.stringify(spouse.dateOfBirth).length > 2
          ? (_cancerPlansValidated = false)
          : (_cancerPlansValidated = true);
      }

      // if family selected, must fill out spouse and dependent info
      if (selections.cancer && cancerSelection[0] === 'family') {
        //check all dependents AND Spouse
        dependents &&
          dependents
            .filter((dep) => !dep.isDeleted)
            .forEach((dep) => {
              if (
                dep.firstName?.length > 0 &&
                dep.lastName?.length > 0 &&
                dep.dob &&
                JSON.stringify(dep.dob).length > 2 &&
                spouse.firstName?.length > 0 &&
                spouse.lastName?.length > 0 &&
                spouse.dateOfBirth &&
                JSON.stringify(spouse.dateOfBirth).length > 2
              ) {
                _cancerPlansValidated = false;
              } else {
                _cancerPlansValidated = true;
              }
            });
      }

      // if family selected, and only member or family options available must fill out spouse and dependent info
      if (selections.cancer && cancerSelection[0] === 'familyOptional') {
        let spouseFilledOut = false;
        let allDepsFilledOut = false;
        if (
          spouse.firstName?.length > 0 &&
          spouse.lastName?.length > 0 &&
          spouse.dateOfBirth &&
          JSON.stringify(spouse.dateOfBirth).length > 2
        ) {
          spouseFilledOut = true;
        }
        //check all dependents AND Spouse
        dependents &&
          dependents
            .filter((dep) => !dep.isDeleted)
            .forEach((dep) => {
              if (
                dep.firstName?.length > 0 &&
                dep.lastName?.length > 0 &&
                dep.dob &&
                JSON.stringify(dep.dob).length > 2
              ) {
                allDepsFilledOut = true;
              } else {
                allDepsFilledOut = false;
              }
            });
        if (!spouseFilledOut && !allDepsFilledOut) _cancerPlansValidated = true;
        else _cancerPlansValidated = false;
      }

      //member only option selected
      if (
        cancerSelection &&
        (cancerSelection[0] === 'member' || !cancerSelection[0])
      ) {
        selections.cancer
          ? (_cancerPlansValidated = false)
          : (_cancerPlansValidated = true);
      }
    } else _cancerPlansValidated = false;

    //no cancer plan selected
    if (!selections.cancer) _cancerPlansValidated = false;

    //if add plans exist
    //add plan not required, but if a spouse, dependent, or family is selected, must fill out spouse and dependent info
    if (results?.addPlan) {
      //if member and dependent(s) selected must fill out dependent info
      if (selections.add && addSelection.includes('member and dependents')) {
        dependents &&
          dependents
            .filter((dep) => !dep.isDeleted)
            .forEach((dep) => {
              if (
                dep.firstName?.length > 0 &&
                dep.lastName?.length > 0 &&
                dep.dob &&
                JSON.stringify(dep.dob).length > 2
              ) {
                _addPlansValidated = false;
              } else {
                _addPlansValidated = true;
              }
            });
      }

      //if member and spouse selected, must fill out spouse info
      if (selections.add && addSelection.includes('member and spouse')) {
        spouse.firstName?.length > 0 &&
        spouse.lastName?.length > 0 &&
        spouse.dateOfBirth &&
        JSON.stringify(spouse.dateOfBirth).length > 2
          ? (_addPlansValidated = false)
          : (_addPlansValidated = true);
      }

      // if family selected, must fill out spouse and dependent info
      if (selections.add && addSelection.includes('family')) {
        //check all dependents AND Spouse
        dependents &&
          dependents
            .filter((dep) => !dep.isDeleted)
            .forEach((dep) => {
              if (
                dep.firstName?.length > 0 &&
                dep.lastName?.length > 0 &&
                dep.dob &&
                JSON.stringify(dep.dob).length > 2 &&
                spouse.firstName?.length > 0 &&
                spouse.lastName?.length > 0 &&
                spouse.dateOfBirth &&
                JSON.stringify(spouse.dateOfBirth).length > 2
              ) {
                _addPlansValidated = false;
              } else {
                _addPlansValidated = true;
              }
            });
      }

      // if family selected, and only member or family options available must fill out spouse and dependent info
      if (selections.add && addSelection.includes('familyOptional')) {
        let spouseFilledOut = false;
        let allDepsFilledOut = false;
        if (
          spouse.firstName?.length > 0 &&
          spouse.lastName?.length > 0 &&
          spouse.dateOfBirth &&
          JSON.stringify(spouse.dateOfBirth).length > 2
        ) {
          spouseFilledOut = true;
        }
        //check all dependents AND Spouse
        dependents &&
          dependents
            .filter((dep) => !dep.isDeleted)
            .forEach((dep) => {
              if (
                dep.firstName?.length > 0 &&
                dep.lastName?.length > 0 &&
                dep.dob &&
                JSON.stringify(dep.dob).length > 2
              ) {
                allDepsFilledOut = true;
              } else {
                allDepsFilledOut = false;
              }
            });
        if (!spouseFilledOut && !allDepsFilledOut) _addPlansValidated = true;
        else _addPlansValidated = false;
      }

      //member only option selected
      let nothingSelected = true;
      if (addSelection?.length > 0) {
        for (let selection of addSelection) {
          if (selection != null) nothingSelected = false;
        }
      }
      //member only option selected
      if (addSelection?.includes('member') || nothingSelected) {
        selections.add
          ? (_addPlansValidated = false)
          : (_addPlansValidated = true);
      }
    } else _addPlansValidated = false;

    //no plan selected
    if (!selections.add) _addPlansValidated = false;

    //if hospital plans exist
    //if a spouse, dependent, or family is selected, must fill out spouse and dependent info
    if (results?.hospitalPlan) {
      //if member and dependent(s) selected must fill out dependent info
      if (
        selections.hospital &&
        hospitalSelection[0] === 'member and dependents'
      ) {
        dependents &&
          dependents
            .filter((dep) => !dep.isDeleted)
            .forEach((dep) => {
              if (
                dep.firstName?.length > 0 &&
                dep.lastName?.length > 0 &&
                dep.dob &&
                JSON.stringify(dep.dob).length > 2
              ) {
                _hospitalPlansValidated = false;
              } else {
                _hospitalPlansValidated = true;
              }
            });
      }

      //if member and spouse selected, must fill out spouse info
      if (selections.hospital && hospitalSelection[0] === 'member and spouse') {
        spouse.firstName?.length > 0 &&
        spouse.lastName?.length > 0 &&
        spouse.dateOfBirth &&
        JSON.stringify(spouse.dateOfBirth).length > 2
          ? (_hospitalPlansValidated = false)
          : (_hospitalPlansValidated = true);
      }

      // if family selected, must fill out spouse and dependent info
      if (selections.hospital && hospitalSelection[0] === 'family') {
        //check all dependents AND Spouse
        dependents &&
          dependents
            .filter((dep) => !dep.isDeleted)
            .forEach((dep) => {
              if (
                dep.firstName?.length > 0 &&
                dep.lastName?.length > 0 &&
                dep.dob &&
                JSON.stringify(dep.dob).length > 2 &&
                spouse.firstName?.length > 0 &&
                spouse.lastName?.length > 0 &&
                spouse.dateOfBirth &&
                JSON.stringify(spouse.dateOfBirth).length > 2
              ) {
                _hospitalPlansValidated = false;
              } else {
                _hospitalPlansValidated = true;
              }
            });
      }

      // if family selected, and only member or family options available must fill out spouse and dependent info
      if (selections.hospital && hospitalSelection[0] === 'familyOptional') {
        let spouseFilledOut = false;
        let allDepsFilledOut = false;
        if (
          spouse.firstName?.length > 0 &&
          spouse.lastName?.length > 0 &&
          spouse.dateOfBirth &&
          JSON.stringify(spouse.dateOfBirth).length > 2
        ) {
          spouseFilledOut = true;
        }
        //check all dependents AND Spouse
        dependents &&
          dependents
            .filter((dep) => !dep.isDeleted)
            .forEach((dep) => {
              if (
                dep.firstName?.length > 0 &&
                dep.lastName?.length > 0 &&
                dep.dob &&
                !dep.dobError
                // JSON.stringify(dep.dob).length > 2
              ) {
                allDepsFilledOut = true;
              } else {
                allDepsFilledOut = false;
              }
            });
        if (!spouseFilledOut && !allDepsFilledOut)
          _hospitalPlansValidated = true;
        else _hospitalPlansValidated = false;
      }

      //member only option selected
      if (
        hospitalSelection &&
        (hospitalSelection[0] === 'member' || !hospitalSelection[0])
      ) {
        selections.hospital
          ? (_hospitalPlansValidated = false)
          : (_hospitalPlansValidated = true);
      }
    } else _hospitalPlansValidated = false;

    //no plan selected
    // if (!selections.hospital) _hospitalPlansValidated = false; //hospital is required, so we need them to select an option

    //Life plan selection validation
    let _spouseInfoValidated = false;
    let _dependentInfoValidated = false;

    //check if spouse selected within LADD plan, if yes, require spouse firstName, lastName, dob before enabling 'next' button
    if (selections.ladd[1] > 0) {
      // console.log('spouse in validation',spouse)
      spouse.firstName?.length > 0 &&
      spouse.lastName?.length > 0 &&
      spouse.dateOfBirth &&
      !spouse.dobError &&
      !spouse.ageError
        ? // JSON.stringify(spouse.dateOfBirth).length > 2 // 0 for string, but 2 for date object, this gets both
          (_spouseInfoValidated = true)
        : (_spouseInfoValidated = false);
    } else if (selections.ladd[1] === 0) _spouseInfoValidated = true;
    // check if dependent selected within LADD plan, if yes, require dependent firstName, lastName, dob before enabling 'next' button
    if (selections.ladd[2] > 0) {
      _dependentInfoValidated = true;

      // console.log('dep in validation',dependents)
      dependents &&
        dependents
          .filter((dep) => !dep.isDeleted)
          .forEach((dep) => {
            if (
              !(dep.firstName?.length > 0) ||
              !(dep.lastName?.length > 0) ||
              !dep.dob ||
              dep.dobError ||
              !dep.gender
            ) {
              _dependentInfoValidated = false;
            }
            // else {
            //   _dependentInfoValidated = false;
            // }
          });
    } else if (selections.ladd[2] === 0) _dependentInfoValidated = true;

    //recheck validations afer spouse/dependent info has changed to ensure selection from member, spouse, and dependent has been made, even if declined
    if (results?.lifeADDPlan) {
      laddSelectionMade && _spouseInfoValidated && _dependentInfoValidated
        ? (_laddPlansValidated = false)
        : (_laddPlansValidated = true);
    } else _laddPlansValidated = false;

    // console.log('spouseInfoValidated',_spouseInfoValidated)
    // console.log('dependentInfoValidated',_dependentInfoValidated)
    // console.log('laddPlansValidated',_laddPlansValidated)
    // console.log('stdPlansValidated',_stdPlansValidated)
    // console.log('ltdPlansValidated',_ltdPlansValidated)

    //set the value of true (keep next button disabled - waiting for more information from user), or false (all required info and Next button is enabled)
    //if all the above plans meet validation (are set to false), set total validation value of false, else true (disabled=true) if all requirements not met
    if (
      !_stdPlansValidated &&
      !_ltdPlansValidated &&
      !_laddPlansValidated &&
      !_accidentPlansValidated &&
      !_cancerPlansValidated &&
      !_addPlansValidated &&
      !_hospitalPlansValidated
    ) {
      setPlanInfoMissing(false);
    } else setPlanInfoMissing(true);

    // Checks if beneficiaries are required before pushing to benny page
    if (selections.ladd[0] !== 0) {
      setBenRequired(true);
    } else {
      setBenRequired(false);
    }
  }, [
    selections,
    laddSelectionMade,
    spouse,
    spouse?.dobError,
    spouse?.ageError,
    dependents,
    dependents.map((d) => d?.dobError),
    accidentSelection,
    cancerSelection,
    addSelection,
    hospitalSelection,
  ]);

  // console.log('planInfoValidation', planInfoMissing)

  // Next Button Validation for Beneficiaries
  const [benValidated, setBenValidated] = useState(true);
  const [addedBennyNotSaved, setAddedBennyNotSaved] = useState(false);

  useEffect(() => {
    let anyTrue = false;
    let percentTotalCheck = 0;
    let contingentAnyTrue = false;
    let contingentPercentTotalCheck = 0;
    primaryCheckboxes?.length > 0 &&
      primaryCheckboxes.map((ben) => {
        if (ben.checked == true) {
          anyTrue = true;
          percentTotalCheck += ben.amount;
        }
        // console.log('benny in validator',ben)
      });
    contingentCheckboxes?.length > 0 &&
      contingentCheckboxes.map((ben) => {
        if (ben.checked == true) {
          contingentAnyTrue = true;
          contingentPercentTotalCheck += ben.amount;
        }
      });
    let validated = false;
    if (anyTrue) {
      validated = percentTotalCheck == 100;
    }
    if (anyTrue && contingentAnyTrue) {
      validated =
        percentTotalCheck == 100 && contingentPercentTotalCheck == 100;
    }
    if (validated && !addedBennyNotSaved) {
      setBenValidated(false);
    } else {
      setBenValidated(true);
    }
    // if (anyTrue == false) {
    //   setBenValidated(true);
    // } else {
    //   setBenValidated(false);
    // }
    // console.log(primaryCheckboxes)
  }, [primaryCheckboxes, contingentCheckboxes, addedBennyNotSaved]);

  //PHONE LOGIC
  const [phones, setPhones] = useState([]);
  const [primaryPhoneFromWrapper, setPrimaryPhoneFromWrapper] = useState({});
  const [secondaryPhoneFromWrapper, setSecondaryPhoneFromWrapper] = useState(
    {}
  );

  const getPhones = async () => {
    let _phones;
    try {
      _phones = await memberService.getPhones();
    } catch (error) {
      console.log('error getting phones', error);
    }

    setPhones(_phones);

    let preferredNumber = _phones?.find((phone) => {
      if (phone.isPreferred) return phone;
    });

    let secondPhoneNumber = _phones?.find((phone) => {
      if (phone.isPreferred === false) {
        return phone;
      }
    });
    setPrimaryPhoneFromWrapper(preferredNumber ? preferredNumber : {});
    setSecondaryPhoneFromWrapper(secondPhoneNumber ? secondPhoneNumber : {});
  };

  useEffect(() => {
    getPhones();
  }, []);

  const handlePhoneInsert = async () => {
    //if primary or secondary numbers already exist, update these numbers w/ phone id
    if (primaryPhoneFromWrapper && primaryPhoneFromWrapper.id) {
      try {
        await memberService.updatePhone(primaryPhoneFromWrapper);
      } catch (error) {
        console.log('error updating primary/mobile phone', error);
      }
    }
    if (secondaryPhoneFromWrapper && secondaryPhoneFromWrapper.id) {
      try {
        await memberService.updatePhone(secondaryPhoneFromWrapper);
      } catch (error) {
        console.log('error updating secondary/home phone', error);
      }
    }
    //if primary or secondary numbers don't exist, create these numbers w/ NO phone id
    if (primaryPhoneFromWrapper && !primaryPhoneFromWrapper.id) {
      try {
        await memberService.insertPhone(primaryPhoneFromWrapper);
      } catch (error) {
        console.log('error inserting a new primary/mobile phone', error);
      }
    }
    if (secondaryPhoneFromWrapper && !secondaryPhoneFromWrapper.id) {
      try {
        await memberService.insertPhone(secondaryPhoneFromWrapper);
      } catch (error) {
        console.log('error inserting a new secondary/home phone', error);
      }
    }
    getPhones();
  };

  const [memberAgent, setMemberAgent] = useState();
  const [localMemberAgentInfo, setLocalMemberAgentInfo] = useState();
  const getMemberAgent = async () => {
    try {
      let _memberAgent = await memberService.getMemberAgent();

      if (_memberAgent) {
        setMemberAgent(_memberAgent);
        // console.log(_memberAgent)
        setLocalMemberAgentInfo(_memberAgent);
      } else {
        setMemberAgent(false);
        setLocalMemberAgentInfo(false);
      }
    } catch (error) {
      console.log('error grabbing memberAgent', error);
      setMemberAgent(false);
      setLocalMemberAgentInfo(false);
    }
  };
  useEffect(() => {
    getMemberAgent();
  }, []);

  const [timeLeft, setTimeLeft] = useState('About 3 minutes left');
  const [progress, setProgress] = useState(20);

  const [scrollAtBottom, setScrollAtBottom] = useState(true);

  //this top level NAW state is used for the sticky progress bar, which has the next button
  const [notActivelyWorking, setNotActivelyWorking] = useState(null);

  const handleNawExit = async () => {
    if (history.location == 'personalinfo') {
      try {
        await memberService.updateMember(fullMemberObj);
      } catch (error) {
        console.log('error updating member', error);
      }
      handlePhoneInsert();
      setLoading(false);
      if (passPersonalValidation()) {
        history.push('progress-saved', { ...history.location.state });
      } else {
        setShowErrors(true);
        // console.log("Howdy");
      }
    } else {
      history.push('progress-saved', { ...history.location.state });
    }
  };
  useEffect(() => {
    console.log('fullMemberObj work status change', fullMemberObj.workStatusId);
    if (fullMemberObj.workStatusId > 0 && fullMemberObj.workStatusId != 1) {
      setNotActivelyWorking(true);
    } else {
      setNotActivelyWorking(false);
    }
  }, [fullMemberObj.workStatusId]);

  const [nawReason, setNawReason] = useState(null);

  // Next Button Validation for Personal Info
  const [personalValidated, setPersonalValidated] = useState(false);
  useEffect(() => {
    checkIndividualFieldsValidation();
  }, [fullMemberObj, primaryPhoneFromWrapper]);

  const [personalFields, setPersonalFields] = useState({});
  const [showErrors, setShowErrors] = useState(false);

  const checkIndividualFieldsValidation = () => {
    let _personalFields = {
      firstName: false,
      lastName: false,
      gender: false,
      street1: false,
      city: false,
      state: false,
      zipCode: false,
      workStatus: false,
      jobTitle: false,
      mobilePhone: false,
      social: false,
    };
    let mobilePhone = primaryPhoneFromWrapper?.number;
    if (fullMemberObj.gender > 0) {
      _personalFields.gender = true;
    }
    if (fullMemberObj.street1 !== '' && fullMemberObj.street1 !== null) {
      _personalFields.street1 = true;
    }
    if (fullMemberObj.city !== '' && fullMemberObj.city !== null) {
      _personalFields.city = true;
    }
    if (fullMemberObj.state !== '' && fullMemberObj.state !== null) {
      _personalFields.state = true;
    }
    if (fullMemberObj.zipCode !== '' && fullMemberObj.zipCode !== null) {
      _personalFields.zipCode = true;
    }
    if (fullMemberObj.workStatusId > 0) {
      _personalFields.workStatus = true;
    }
    if (fullMemberObj.jobTitle !== '' && fullMemberObj.jobTitle !== null) {
      _personalFields.jobTitle = true;
    }
    if (mobilePhone && mobilePhone.length == 10) {
      _personalFields.mobilePhone = true;
    }
    if (require9DigitSocial && fullMemberObj.social?.length == 9) {
      _personalFields.social = true;
    }
    if (!require9DigitSocial && fullMemberObj.social?.length >= 4) {
      _personalFields.social = true;
    }
    if (fullMemberObj.lastName !== '' && fullMemberObj.lastName !== null) {
      _personalFields.lastName = true;
    }
    if (fullMemberObj.firstName !== '' && fullMemberObj.firstName !== null) {
      _personalFields.firstName = true;
    }
    setPersonalFields(_personalFields);
  };

  const passPersonalValidation = () => {
    if (
      personalFields.firstName &&
      personalFields.lastName &&
      personalFields.gender &&
      personalFields.street1 &&
      personalFields.city &&
      personalFields.state &&
      personalFields.zipCode &&
      personalFields.workStatus &&
      personalFields.jobTitle &&
      personalFields.mobilePhone &&
      personalFields.social
    ) {
      return true;
    } else return false;
  };

  //The rules being checked for this are all in the backend, hence, backendValidation
  const [backendValidation, setBackendValidation] = useState(null);
  const checkBackendValidation = async () => {
    let _backendValidation = false;
    let validationReturn;
    try {
      validationReturn = await enrollmentService.validateEnrollment();
    } catch (error) {
      console.log('error checking backend validation', error);
    }
    console.log('backend validation return', validationReturn);

    if (validationReturn?.length > 0) {
      var latestPlan = validationReturn.find(
        (groupPlan) => groupPlan.groupPlanId === results.groupPlanId
      );
      if (!latestPlan?.hasIneligibleItemSelected) _backendValidation = true;
    }

    setBackendValidation(_backendValidation);
    // setResults(validationReturn);
    return [latestPlan, _backendValidation];
  };

  return (
    <>
      {loading && (
        <>
          <InfinityLoader
            style={
              externalEnrollmentSharedStyles.infinityLoaderSingleEnrollWrapper
            }
          />
          <style>
            {`
                  div.lottie-div > svg {
                     height:55px!important;
                     position:absolute;
                     top:50%;
                  }
               `}
          </style>
        </>
      )}
      {openEnrollment ? (
        <Box
          id="enrollment-container"
          flex={1}
          sx={{
            display: 'block',
            flexWrap: 'wrap',
            height: '100vh',
            margin: 'auto',
            // overflowY: 'hidden',
            overflowX: 'hidden',
            WebkitOverflowScrolling: 'touch',
            bgcolor: 'background.default',
            color: 'text.primary',
          }}
        >
          <enrollmentContext.Provider value={contextProps}>
            {isEnrolled ? (
              <>
                <Route
                  path="/enrollments/:groupPlanId/success"
                  render={() => (
                    <>
                      <EnrollmentComplete
                        guid={guid}
                        results={results}
                        loading={loading}
                        setLoading={setLoading}
                        notActivelyWorking={notActivelyWorking}
                      />
                      <PoweredBy />
                    </>
                  )}
                />
              </>
            ) : (
              <>
                <Route
                  path="/enrollments/:groupPlanId/success"
                  render={() => (
                    <>
                      <EnrollmentComplete
                        guid={guid}
                        results={results}
                        loading={loading}
                        setLoading={setLoading}
                        notActivelyWorking={notActivelyWorking}
                      />
                      <PoweredBy />
                    </>
                  )}
                />

                <Route
                  path="/enrollments/:groupPlanId/payment"
                  render={() => (
                    <>
                      <EnrollmentPayment
                        guid={guid}
                        setGuid={setGuid}
                        results={results}
                        selections={selections}
                        runningTotal={runningTotal}
                        updateResults={updateResults}
                        userInfo={userInfo}
                        enrollmentId={results?.enrollmentId}
                        // expanded={expanded} setExpanded={setExpanded}
                        // showPayment={showPayment} setShowPayment={setShowPayment}
                        fullMemberObj={fullMemberObj}
                        setLoading={setLoading}
                        nawReason={nawReason}
                        loading={loading}
                      />
                      {/* <StickyProgressTotal runningTotal={runningTotal} nextClickHandler={handleNextClick} selections={selections} nextLocation={'success'} /> */}
                    </>
                  )}
                />

                <Route
                  path="/enrollments/:groupPlanId/personalinfo"
                  render={() => (
                    <>
                      <EnrollmentPersonalInfo
                        fullMemberObj={fullMemberObj}
                        setFullMemberObj={setFullMemberObj}
                        personalFields={personalFields}
                        showErrors={showErrors}
                        checkValidation={passPersonalValidation()}
                        results={results}
                        phones={phones}
                        setPhones={setPhones}
                        // subgroupOrgs={subgroupOrgs}
                        primaryPhoneFromWrapper={primaryPhoneFromWrapper}
                        setPrimaryPhoneFromWrapper={setPrimaryPhoneFromWrapper}
                        secondaryPhoneFromWrapper={secondaryPhoneFromWrapper}
                        setSecondaryPhoneFromWrapper={
                          setSecondaryPhoneFromWrapper
                        }
                        fullSocial={fullSocial}
                        require9DigitSocial={require9DigitSocial}
                        setRequire9DigitSocial={setRequire9DigitSocial}
                        getFullSocial={getFullSocial}
                        checkBackendValidation={checkBackendValidation}
                        scrollIntoView={scrollIntoView}
                        // backendValidation={backendValidation}
                        nawReason={nawReason}
                        setNawReason={setNawReason}
                      />
                      <StickyProgressTotal
                        runningTotal={runningTotal}
                        nextValidation={passPersonalValidation()}
                        nextClickHandler={handleNextClick}
                        // notActivelyWorking={notActivelyWorking}
                        // handleNawExit={handleNawExit}
                        timeLeft={timeLeft}
                        progress={progress}
                        // selections={selections}
                        nextLocation={'payment'}
                        lastLocation={benRequired ? 'beneficiaries' : 'plans'}
                        allowBack={true}
                        loading={loading}
                      />
                    </>
                  )}
                />

                <Route
                  path="/enrollments/:groupPlanId/beneficiaries"
                  render={() => (
                    <>
                      <EnrollmentBeneficiaries
                        primaryCheckboxes={primaryCheckboxes}
                        setPrimaryCheckboxes={setPrimaryCheckboxes}
                        contingentCheckboxes={contingentCheckboxes}
                        setContingentCheckboxes={setContingentCheckboxes}
                        primaryBeneficiaries={primaryBeneficiaries}
                        setPrimaryBeneficiaries={setPrimaryBeneficiaries}
                        contingentBeneficiaries={contingentBeneficiaries}
                        setContingentBeneficiaries={setContingentBeneficiaries}
                        beneficiariesInDB={beneficiariesInDB}
                        setBeneficiariesInDB={setBeneficiariesInDB}
                        potentialBeneficiaries={potentialBeneficiaries}
                        setPotentialBeneficiaries={setPotentialBeneficiaries}
                        getInitialBeneficiaries={getInitialBeneficiaries}
                        storedAllocations={storedAllocations}
                        setStoredAllocations={setStoredAllocations}
                        primaryBennyAdd={primaryBennyAdd}
                        setPrimaryBennyAdd={setPrimaryBennyAdd}
                        contingentBennyAdd={contingentBennyAdd}
                        setContingentBennyAdd={setContingentBennyAdd}
                        results={results}
                        setResults={setResults}
                        updateResults={updateResults}
                        userInfo={userInfo}
                        benficiariesObj={benficiariesObj}
                        setBeneficiariesObj={setBeneficiariesObj}
                        setLoading={setLoading}
                        spouse={spouse}
                        setSpouse={setSpouse}
                        genderTypes={genderTypes}
                        // medBreakpointHit={medBreakpointHit}
                        addedBennyNotSaved={addedBennyNotSaved}
                        setAddedBennyNotSaved={setAddedBennyNotSaved}
                        loading={loading}
                      />
                      {/* <PoweredBy/> */}
                      <StickyProgressTotal
                        runningTotal={runningTotal}
                        nextValidation={benValidated}
                        nextClickHandler={handleNextClick}
                        timeLeft={timeLeft}
                        progress={progress}
                        // selections={selections}
                        nextLocation={'personalinfo'}
                        lastLocation={'plans'}
                        allowBack={true}
                      />
                    </>
                  )}
                />

                <Route
                  path="/enrollments/:groupPlanId/plans"
                  render={() => (
                    <>
                      <EnrollmentPlans
                        userInfo={userInfo}
                        setUserInfo={setUserInfo}
                        results={results}
                        setResults={setResults}
                        updateResults={updateResults}
                        recommendations={recommendations}
                        setRecomendations={setRecomendations}
                        selections={selections}
                        setSelections={setSelections}
                        selectionRec={selectionRec}
                        selectionHandler={selectionHandler}
                        calculated={calculated}
                        runningTotal={runningTotal}
                        fullMemberObj={fullMemberObj}
                        dependents={dependents}
                        setDependents={setDependents}
                        saveDependents={saveDependents}
                        spouse={spouse}
                        setSpouse={setSpouse}
                        spouseRatesLoading={spouseRatesLoading}
                        genderTypes={genderTypes}
                        scrollAtBottom={scrollAtBottom}
                        setScrollAtBottom={setScrollAtBottom}
                        setGuid={setGuid}
                        setLaddSelectionMade={setLaddSelectionMade}
                        accidentSelection={accidentSelection}
                        setAccidentSelection={setAccidentSelection}
                        cancerSelection={cancerSelection}
                        setCancerSelection={setCancerSelection}
                        addSelection={addSelection}
                        setAddSelection={setAddSelection}
                        hospitalSelection={hospitalSelection}
                        setHospitalSelection={setHospitalSelection}
                        handleLaddSaveAndContinue={handleLaddSaveAndContinue}
                        saveLadd={saveLadd}
                        backendValidation={backendValidation}
                        setBackendValidation={setBackendValidation}
                        loading={loading}
                        // medBreakpointHit={medBreakpointHit}
                      />
                      {/* <PoweredBy/> */}
                      <StickyProgressTotal
                        runningTotal={runningTotal}
                        nextValidation={planInfoMissing}
                        nextClickHandler={handleNextClick}
                        timeLeft={timeLeft}
                        progress={progress}
                        scrollAtBottom={scrollAtBottom}
                        // selections={selections}
                        nextLocation={'beneficiaries'}
                        allowBack={false}
                      />
                      {/* <PoweredBy/> */}
                    </>
                  )}
                />

                <Route
                  path="/enrollments/:groupPlanId/progress-saved"
                  render={() => (
                    <>
                      <EnrollmentProgressSaved
                        guid={guid}
                        results={results}
                        loading={loading}
                        setLoading={setLoading}
                        fullMemberObj={fullMemberObj}
                      />
                      {/* <PoweredBy/> */}
                      {/* <StickyProgressTotal
                        runningTotal={runningTotal}
                        nextValidation={planInfoMissing}
                        nextClickHandler={handleNextClick}
                        timeLeft={timeLeft}
                        progress={progress}
                        scrollAtBottom={scrollAtBottom}
                        // selections={selections}
                        nextLocation={"beneficiaries"}
                        allowBack={false}
                      /> */}
                      {/* <PoweredBy/> */}
                    </>
                  )}
                />

                <Route
                  exact
                  path="/enrollments/:groupPlanId/"
                  render={() => (
                    <>
                      <ExternalEnrollmentCalculator
                        userInfo={userInfo}
                        setUserInfo={setUserInfo}
                        results={results}
                        setResults={setResults}
                        setRecomendations={setRecomendations}
                        selections={selections}
                        selectionRec={selectionRec}
                        selectionHandler={selectionHandler}
                        setCalculated={setCalculated}
                        setLoading={setLoading}
                        dependents={dependents}
                        saveDependents={saveDependents}
                        selectedEnrollment={results}
                        getFullMemberObj={getFullMemberObj}
                        fullMemberObj={fullMemberObj}
                        setFullMemberObj={setFullMemberObj}
                        handleNawExit={handleNawExit}
                        memberAgent={memberAgent}
                        setMemberAgent={setMemberAgent}
                        localMemberAgentInfo={localMemberAgentInfo}
                        setLocalMemberAgentInfo={setLocalMemberAgentInfo}
                        getMemberAgent={getMemberAgent}
                      />
                      {/* <PoweredBy /> */}
                      {/* <StickyProgressTotal runningTotal={runningTotal} nextClickHandler={handleNextClick} selectiotCns={selections} /> */}
                    </>
                  )}
                />
              </>
            )}
          </enrollmentContext.Provider>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}
